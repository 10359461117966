import React, { Component } from 'react'
import { BASE_URL, YGK_URL, YKT_URL, Axios } from '../../utils/url'
import { Tabs, NavBar,  List, DatePicker, Picker, Radio, Button, Toast, ImagePicker, Icon, Modal } from 'antd-mobile';

import ytg from '../../assets/images/zfcg.gif';
import wtg from '../../assets/images/wtg.png';
const Item = List.Item;
const Brief = Item.Brief;
var tag = '';
var t = null;
const now = Date.now();
export default class cryz extends Component {
    constructor(props) {
        super(props)
        if(!window.location.href.indexOf("&sjs")!=-1){
            window.location.href = window.location.href+"&sjs="+now;
        }
        const openid = this.props.location.search;
        if (openid.length > 0) {
            var open = openid.split("&");
            var openidArr = open[0].split("=");
            var openid1 = openidArr[1];
            var tagArr = open[1].split("=");
            tag = tagArr[1];
            var params = new URLSearchParams();
            localStorage.setItem("openID", openid1);
            params.append('openid', openid1);
            Axios({
                method: 'post',
                url: "http://8.140.128.117:18080/clydd/bRyzcController/getUserInfo",
                data: params
            }).then((res) => {
                localStorage.setItem("userInfo", JSON.stringify(res.data.userInfo));
                const userInfo = JSON.parse(localStorage.getItem("userInfo"));
                this.setState({
                    ryxm: userInfo.ryxm,
                    sqdw: userInfo.rysx20120716p0026,
                    sfzh: userInfo.sfzh,
                    zz: userInfo.rysx20120716p0051,
                    ektbh: userInfo.rysx20120716p0046,
                    sqrid: userInfo.ryid,
                    phone: userInfo.rysx20120716p0048,
                    userInfo: userInfo,
                    dwbm: userInfo.dwbm,
                });
                this.getList(userInfo.sfzh);

            });
        }
        this.state = {
            wyyList: [],
            yyyList:[],
        }

    }

    //进页面加载
    componentDidMount = () => {
        this.forceUpdate();
        // this.getList();
        // this.getList();
        this.getTime1();
    }
    //动态获取时间
    getTime1=()=>{
        const [data,setDate]='';
        const getNewDate = () => {
            const time = new Date();
            const year = time.getFullYear(); //年
            const month = time.getMonth() + 1 < 10 ? "0" + (time.getMonth() + 1) : time.getMonth() + 1;; //月
            const day = time.getDate() < 10 ? "0" + time.getDate() : time.getDate();; //日
            const hour = time.getHours() < 10 ? "0" + time.getHours() : time.getHours(); //时
            const minutes = time.getMinutes() < 10 ? "0" + time.getMinutes() : time.getMinutes();//分
            const s = time.getSeconds(); //秒
            const seconds = s <= 9 ? "0" + s : s;
            const t = year + '年' + month + '月' + day + "日" + " " + hour + ":" + minutes + ":"
                      + seconds;
            this.setState({
                data:t
            })

        }
        setInterval(getNewDate, 1000)
        return <div style={{fontSize:'25px',fontWeight:'bold',textAlign:'center',marginTop:'70px'}}>{this.state.data}</div>
    }
    //验证
    getList = (e) => {
        const url = BASE_URL + 'crcsyyOrder/checkYy';
        var params = new URLSearchParams();
        params.append('sfz', this.state.sfzh);
        params.append('place_id', tag);
        Axios({
            method: 'post',
            url: url,
            data: params,
        }).then((res) => {
            if (res.data.length > 0) {
                this.setState({
                    wyyList:res.data,
                }, () => {
                    // this.setMapYyzList();
                    this.getCount();
                });

            }else{
                this.setState({
                    iswyy:true,
                }, () => {
                    this.setMapYyzList();
                });
            }
        });
    }
    //统计人数
    getCount = () =>{
        const url = BASE_URL + 'crcsyyOrder/getYyCount';
        var params = new URLSearchParams();
        params.append('place_id', tag);
        Axios({
            method: 'post',
            url: url,
            data: params,
        }).then((res) => {
            if (res.data.length > 0) {
                this.setState({
                    zrs:res.data[0].zrs
                }, () => {
                    this.setMapYyzList();
                });

            }
        });
    }
    setMapYyzList = () => {
        const list = this.state.wyyList
        if(list.length>0){
            return (
                <div class="live">
                    <img src={ytg} className="logo"></img>
                        <div style={{marginTop:'20px',fontWeight:'bold',fontSize:'25px'}}>验证通过</div>
                        <div style={{marginTop:'20px',fontWeight:'bold',fontSize:'25px',lineHeight:'50px'}}>您是第{this.state.zrs}位在{list[0].order_date}<br />{list[0].check_start_time}至{list[0].check_end_time}入场{list[0].place_name}的人员</div>
                </div>

            )
        }else{
            if(this.state.iswyy){
                return (
                    <div>
                        <img src={wtg} className="logo"></img>
                            <div style={{marginTop:'20px',fontWeight:'bold',fontSize:'25px',color:'red'}}>验证未通过</div>
                            <div style={{marginTop:'20px',fontWeight:'bold',fontSize:'25px',color:'red',lineHeight:'50px'}}>请在预约时间段内进行验证<br />或提前预约再进入场所验证</div>
                    </div>

                )
            }

        }
    }
    render() {
        return (
            <div style={{ marginTop: ".89rem" }}>
                {/* 导航栏#0F84D6 */}
                <NavBar
                    mode="dark"
                >场所验证</NavBar>
                <div style={{ marginTop: "50px" }}>
                    {this.getTime1()}
                    <div style={{textAlign:'center'}}>
                        {this.setMapYyzList()}
                    </div>

                </div>

            </div>
        )
    }
}
