import './newygkzhxx.css'
import React, { Component } from 'react'
import {NavBar,Icon,List} from 'antd-mobile';
import {BASE_URL,NEW_YGK_URL, Axios } from '../../../utils/url';

const type = "POST";
const Item = List.Item;
export default class Newygkzhxx extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cardNo: '',
            idCardNO: '',
            personName: '',
            employeeNo: '',
            phoneNO: '',
            title: '',
            photo: '',
            unitName: '',
            permissionDate: '',
            permissionName:[],
            consumePermissionDate:'',
            consumePermissionName:[],
            accountList:[],
            isShow: false,
            msg:'数据正在加载中。。。。。。',
        }
    }
    componentDidMount = () => {
        //页面加载是获取登录用户的人员信息
        var userInfo = JSON.parse(localStorage.getItem("userInfo"));
        if (userInfo === null) {
            const openid = this.props.location.search;
            if (openid.length > 0) {
                var open = openid.split("&");
                var openidArr = open[0].split("=");
                var openid1 = openidArr[1];
                var params = new URLSearchParams();
                localStorage.setItem("openID", openid1);
                params.append('openid', openid1);
                Axios({
                    method: 'post',
                    url: BASE_URL+"/bRyzcController/getUserInfo",
                    data: params
                }).then((res) => {
                    localStorage.setItem("userInfo", JSON.stringify(res.data.userInfo));
                    localStorage.setItem("bshtoken", JSON.stringify(res.data.bshtoken));
                    userInfo = JSON.parse(localStorage.getItem("userInfo"));
                    this.setState({
                        yhxm: userInfo.ryxm,
                        idCardNO: userInfo.sfzh
                    })
                    //调用接口服务查询卡片信息
                    this.getPersonInfoByIdCardNo(userInfo.sfzh);
                    this.getPersonInfo(userInfo.sfzh);
                });

            }
        } else {
            this.setState({
                yhxm: userInfo.ryxm,
                idCardNO: userInfo.sfzh
            })
            //调用接口服务查询卡片信息
            this.getPersonInfoByIdCardNo(userInfo.sfzh);
            this.getPersonInfo(userInfo.sfzh);
        }
        //  this.getPersonInfoByIdCardNo('612321199509106233');
        //  this.getPersonInfo('612321199509106233');
    }
    async getPersonInfo(value) {
        console.log("开始获取账户信息");
        let ryobject=JSON.parse(localStorage.getItem('userInfo'));
            // alert(localStorage.getItem('bshtoken'));
            // console.log(localStorage);
          //console.log(ryobject.sfzh);
          const url = NEW_YGK_URL+'replacecard/getPersonAccountByIdCardNo';
          console.log("url:",url)
          var params = new URLSearchParams();
          params.append("idCardNo", value);
          await Axios({
              method: 'post',
              url: url,
              data: params
          }).then((res) => {
              console.log(res);
              if(res.data.flag){
                  this.setState({
                      accountList:  res.data.data.accountList
                    })
              }
                console.log(this.state.accountList);

          });
      }

    //通过身份证号码获取卡片信息
    async getPersonInfoByIdCardNo(value){
        if(value){
            //alert("我开始加载数据并获取人员信息")
            let params ={};
            params.idCardNo = value;
            let url = NEW_YGK_URL + "CardholderController/queryCardInfoByIdCardNo" ;
            // alert("url"+url);
            await Axios({method: type, url: url, data: params}).then(resolve =>{
                // alert(resolve);
               if(resolve.data.flag){
                    const obj = resolve.data.data;
                    if(obj.personName === ""){
                        this.setState({
                            isShow: false
                        })
                    }else{
                            this.setState ({
                                cardNo: "No."+obj.cardNo,
                                idCardNO: obj.id_card_no,
                                personName: obj.personName,
                                employeeNo: obj.hrNo,
                                phoneNO: obj.phoneNo,
                                photo: obj.imgUrl,
                                unitName: obj.unitName,
                                permissionDate: obj.permissionDate,
                                permissionName: obj.permissionName.split(',').map((item,index)=>{
                                    return(
                                        <span key = {index}>
                                            {item}
                                            <br/>
                                        </span>
                                    )
                                }),
                                consumePermissionDate: obj.consumePermissionDate,
                                consumePermissionName: obj.consumePermissionName.split(',').map((item,index)=>{
                                    return(
                                        <span key = {index}>
                                            {item}
                                            <br/>
                                        </span>
                                    )
                                }),
                                isShow: true
                        })
                    }
               }else{
                console.error(resolve.data.msg);
                        this.setState ({
                            msg: resolve.data.msg,
                    })
               }
            }, reject =>{
                console.log(reject);
            })
        }
    }
    //输入框失去焦点是调用根据卡片编号获取卡片信息
    handleChange(val){
        this.getPersonInfoByCardNo(val);
    }

    render() {
        const {accountList,msg,isShow,phoneNO,photo,personName,employeeNo,unitName,cardNo,idCardNO,permissionDate,permissionName,consumePermissionDate,consumePermissionName} = this.state; //解构赋值
        return (
            <div style={{ marginTop: ".89rem" }}>
            <NavBar
                mode="dark"
                key={1}
                icon={<Icon type="left" />}
                onLeftClick={() => this.props.history.push({
                    pathname: './main',
                      search: `?isNone='none'`
                   })}
            >账户信息</NavBar>
            {isShow?(
                <List style={{marginTop:"50px"}}>
                    <Item align='middle'>
                        <div style={{ textAlign: 'center' }} >
                            <img src={photo} style={{ width: '180px', height: '220px', textAlign: 'center' }} alt='照片' />
                        </div>
                    </Item>
                    <Item>姓&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;名：{personName}</Item>
                    <Item>人员编号：{employeeNo}</Item>
                    <Item>联系电话：{phoneNO}</Item>
                    <Item>身份证号：{idCardNO}</Item>
                    <Item>组织机构：{unitName}</Item>
                    <Item>卡片编号：{cardNo}</Item>
                    <Item>门禁期限：{permissionDate}</Item>
                    <Item>门禁权限：
                        {permissionName.map((item,index)=>
                            <li key={index}
                                style={{textOverflow:"ellipsis",whiteSpace:"normal",overflow:"hidden",listStyle:"circle"}}>
                                {item}
                            </li>)}
                    </Item>
                    <Item>消费期限：{consumePermissionDate}</Item>
                    <Item>消费权限：
                        {consumePermissionName.map((item,index)=>
                        <li key={index}
                            style={{textOverflow:"ellipsis",whiteSpace:"normal",overflow:"hidden",listStyle:'circle'}}>
                            {item}
                        </li>)}
                    </Item>
                    <Item>钱包明细：
                    {accountList.map((item,index)=>
                        <li key={index}
                            style={{textOverflow:"ellipsis",whiteSpace:"normal",overflow:"hidden",listStyle:'circle'}}>
                            {item.ACCOUNT_TYPE_NAME+'余额：'+ (item.BALANCE)/100 + "元--过渡金额："+(item.TRANSIT_MONEY/100)+"元"}
                        </li>)}
                    </Item>
                </List>
            ): <List align ='middle' style={{marginTop:"50px"}}>
                    <Item align='middle' style={{color:'red'}}>{msg}</Item>
                </List>
        }
            </div>
        )
    }
}
