import axios from 'axios';

export const BASE_URL = process.env.REACT_APP_URL
export const NEW_YGK_URL = process.env.REACT_APP_NEWYGKURL
export const YGK_URL = process.env.REACT_APP_YGKURL
export const YL_URL = process.env.REACT_APP_YLURL
export const YKT_URL = process.env.REACT_APP_YKTURL
export const WY_URL = process.env.REACT_APP_WYURL
export const PAGE_URL = process.env.REACT_APP_PAGEURL
export const DOWNFILEURL = process.env.REACT_APP_DOWNFILEURL
export const FORMAT = {//金额数字格式化
    number_format: function (number, decimals, dec_point, thousands_sep) {
        /*
         * 参数说明：
         * number：要格式化的数字
         * decimals：保留几位小数
         * dec_point：小数点符号
         * thousands_sep：千分位符号
         * */
        number = (number + '').replace(/[^0-9+-Ee.]/g, '');
        var n = !isFinite(+number) ? 0 : +number,
            prec = !isFinite(+decimals) ? 2 : Math.abs(decimals),
            sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
            dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
            s = '',
            toFixedFix = function (n, prec) {
                var k = Math.pow(10, prec);
                return '' + Math.ceil(n * k) / k;
            };

        s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
        var re = /(-?\d+)(\d{3})/;
        while (re.test(s[0])) {
            s[0] = s[0].replace(re, "$1" + sep + "$2");
        }

        if ((s[1] || '').length < prec) {
            s[1] = s[1] || '';
            s[1] += new Array(prec - s[1].length + 1).join('0');
        }
        return s.join(dec);
    }
};

// axios.defaults.headers.contentType = 'application/x-www-form-urlencoded'

axios.interceptors.request.use(config => {
    const { headers, data, url, params } = config
    if (data && !juggleHas(url, '8.140.128.117')) { // && !url.includes('8.140.128.117')
        //数据加密
        const publicKey = localStorage.getItem('publicKey')
        const contentType = headers['Content-Type']
        if (juggleHas(contentType, 'application/json')) {//json数据
            config.data = new window.SMutil().sm2encrypt(window.Hex.utf8StrToHex(JSON.stringify(data)), publicKey);
        } else if (juggleHas(data.constructor.toString(), 'URLSearchParams')) {
            // let paramsData = {}
            // for (const item of config.data.toString().split("&")) {
            //     const [k, v] = item.split("=")
            //     paramsData[k] = v
            // }
            // config.data = { "querySecretPackage": new window.SMutil().sm2encrypt(window.Hex.utf8StrToHex(JSON.stringify(paramsData)), publicKey) };
            // // config.data = null
            const encryptParams = new URLSearchParams
            const json = {}
            for (const [key, value] of data) {
                json[key] = value
            }
            encryptParams.append('secretPackage',new window.SMutil().sm2encrypt(window.Hex.utf8StrToHex(JSON.stringify(json)), publicKey))
            config.data = encryptParams
        } else if (juggleHas(contentType, 'application/x-www-form-urlencoded')) {
            config.data = {
                secretPackage: new window.SMutil().sm2encrypt(window.Hex.utf8StrToHex(JSON.stringify(data)), publicKey)
            }
        } else if (juggleHas(contentType, 'multipart/form-data') && false) {
            const json = {}
            const formData = new FormData()
            for (const [key, val] of data.entries()) {
                if (val instanceof File) {
                    formData.append(key, val);
                } else {
                    json[key] = val
                }
            }
            formData.append('secretPackage', new window.SMutil().sm2encrypt(window.Hex.utf8StrToHex(JSON.stringify(json)), publicKey));
            config.data = formData
        }
    }
    return config
}, error => Promise.reject(error));

function juggleHas(value, target) {
    if (undefined == target || null == target) return false
    if (!(undefined == value || null == value) && 'string' === typeof(value)) {
        return value.indexOf(target) > -1
    } else if (!(undefined == value || null == value)) {
        return JSON.stringify(value).indexOf(target) > -1
    }
    return false
}

export const Axios = axios