import './ygkzxcz.css'
import React, { Component } from 'react'
import { BASE_URL,YGK_URL, YL_URL, PAGE_URL, Axios } from '../../../utils/url'
import ygk from '../../../assets/images/ygk.png'
import { NavBar, Icon, Card, WingBlank,Toast} from 'antd-mobile';
export default class Ygkzhxxcx extends Component {
    constructor(props) {
        super(props);
        this.state = ({
            ygkxx: [],
            isLoading: false,
            modal: false,
            value: "",
            czje: "",
            item: "",
            ykth: "",
            isbr:""
        });
    }
    componentDidMount = () => {
        var userInfo = JSON.parse(localStorage.getItem("userInfo"));
        if (userInfo === null) {
            const openid = this.props.location.search;
            if (openid.length > 0) {
                var open = openid.split("&");
                var openidArr = open[0].split("=");
                var openid1 = openidArr[1];
                var params = new URLSearchParams();
                localStorage.setItem("openID", openid1);
                params.append('openid', openid1);
                Axios({
                    method: 'post',
                    url: BASE_URL+"/bRyzcController/getUserInfo",
                    data: params
                }).then((res) => {
                    localStorage.setItem("userInfo", JSON.stringify(res.data.userInfo));
                    localStorage.setItem("bshtoken", JSON.stringify(res.data.bshtoken));
                    userInfo = JSON.parse(localStorage.getItem("userInfo"));
                    this.setState({
                        yhxm: userInfo.ryxm,
                        yhid: userInfo.sfzh
                    })
                    this.getYgkxx(userInfo.sfzh, "");
                });

            }
        } else {
            this.setState({
                yhxm: userInfo.ryxm,
                yhid: userInfo.sfzh
            })
            this.getYgkxx(userInfo.sfzh, "");
        }

    }
    onClose = key => () => {
        this.setState({
            [key]: false,
        });
    }
    async getYgkxx(sfzh, ykth) {
        const url = YGK_URL + 'bryxxController/getYgkZhxx';
        var params = new URLSearchParams();
        // params.append("sfzh","652926198501020213");
        params.append("sfzh", sfzh);
        params.append("ykth", ykth);
        Toast.loading("加载中...", 0, null, true);
        await Axios({
            method: 'post',
            url: url,
            data: params
        }).then((res) => {
            Toast.hide();
            if (res.data.ygkxx.length > 0) {
                this.setState({
                    ygkxx: res.data.ygkxx,
                    isbr: res.data.isbr,
                    isLoaded: true
                });
            }else{
                Toast.info("暂未查询到您的员工卡")
            }
        });
    }
    handleOrder = (item) => {
        this.setState({
            modal: true,
            item: item
        });
    }
    setyktxx = () => {
        if (this.state.isbr) {
            return this.state.ygkxx.map((item, key) => (
                <WingBlank size="lg" key={key}>
                    <Card style={{minHeight:"60px"}}>
                        <Card.Body>
                            <div>{item.zhlxmc}</div>
                        </Card.Body>
                        <Card.Footer content={'过渡金额：' + item.gdje / 100 + '元'} extra={<div>{'余额：' + item.ye / 100 + '元'}</div>} />
                        <Card.Footer content={'当前状态：' + item.dqztmc}  style={{marginTop:"8px"}}/>
                    </Card>
                </WingBlank>

            ))
        }
    }
    setygkHeaderxx = () =>{
        if (this.state.isbr) {
            return  (
                <WingBlank size="lg" >
                    <Card style={{minHeight:"50px"}}>
                        <Card.Header
                            title={this.state.ygkxx[0].ryxm}
                            thumb={ygk}
                            extra={<span>{this.state.ygkxx[0].rybh}</span>}
                        />
                    </Card>
                </WingBlank>
            )
        }
    }
    setTips= () =>{
        if (this.state.isbr) {
            return (<div className="gs" >*过渡金额为尚未写卡金额</div>)
        }

    }
    render() {
        return (
            <div style={{ marginTop: ".89rem" }}>
                {/* 导航栏#0F84D6 */}
                <NavBar
                    mode="dark"
                    icon={<Icon type="left" />}
                    onLeftClick={() => this.props.history.goBack()}
                >员工卡信息查询</NavBar>
                <div className="kf">
                    {this.setygkHeaderxx()}
                    {this.setyktxx()}
                    {this.setTips()}
                    {/* <div className="gs" >*过渡金额为尚未写卡金额</div> */}
                </div>
            </div>
        )
    }
}
