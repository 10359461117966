import './ttydbgbd.css';
import React from 'react';
import { BASE_URL, Axios } from '../../utils/url'
import logo from '../../assets/images/logo.png';
import { InputItem, Button, WhiteSpace, Toast, NavBar, Icon } from 'antd-mobile';

export default class Login extends React.Component {
    constructor(props) {
        super(props);
        const _params = window.location.href.split('?')[1].split("&")
        const params = {}
        for (let p of _params) {
            const [k, v] = p.split("=")
            params[k] = v
            sessionStorage.setItem(k, v)
        }
        this.state = {
            zh: params.yhid,
            tag: params.tag,
            mm: '',
            hasErrorPhone: false,
            zfdz: ""
        }
    }

    componentDidMount = () => {
        //判断是否绑定
        var params = new URLSearchParams();
        params.append('yhid', this.state.zh);
        Toast.loading("检测是否已绑定...", 0, null, true);
        Axios({
            method: 'post',
            url: BASE_URL + "bRyzcController/ttisbd",
            data: params
        }).then((res) => {
            Toast.hide();
            console.log(res);
            if (res.data) {
                localStorage.setItem("yhid", this.state.zh);
                localStorage.setItem("tag", "tantu");
                this.props.history.push("/tantumain")

            }

        });
        //未绑定在本页面
        //绑定了跳转主页面
    }
    bangding = () => {

        if (this.state.mm == '') {
            alert("请输入身份证号");
        } else {
            if (this.state.mm.length !== 18) {
                alert("请输入正确的身份证号");
            } else {
                var params = new URLSearchParams();
                params.append('yhid', this.state.zh);
                params.append('sfzh', this.state.mm);
                Toast.loading("绑定中...", 0, null, true);
                Axios({
                    method: 'post',
                    url: BASE_URL + "bRyzcController/ttbd",
                    data: params
                }).then((res) => {
                    Toast.hide();
                    if (res.data) {
                        // window.location.href = "http://www.bzbsh.cn/wxgzhcs/#/tantumain?yhid="+this.state.zh+"&tag=tantu";
                        localStorage.setItem("yhid", this.state.zh);
                        localStorage.setItem("tag", "tantu");
                        window.close();
                         this.props.history.push("/tantumain")
                        //window.location.replace("http://www.bzbsh.cn/wxgzhcs/#/tantumain");
                    } else {
                        alert("绑定失败，请重新绑定");
                    }
                });
            }
        }
    }
    //获取密码
    getMm = (value) => {
        this.setState({
            mm: value,
        });
    }
    render() {
        return (

            <div className="App">

                <img src={logo} className="logo"></img>
                <p className="app-name">宝石花移动服务大厅</p>
                <div className="login">
                    <InputItem
                        readOnly
                        type="text"
                        placeholder="用户id"
                        value={this.state.zh}
                        error={this.state.hasErrorPhone}
                        onErrorClick={this.onErrorClickPhone}
                        onChange={this.onChangePhone}
                    >用户id</InputItem>
                    <InputItem
                        type="text"
                        placeholder="请输入身份证号"
                        value={this.state.mm}
                        onChange={this.getMm}
                    >身份证号</InputItem>
                    <div className="gs" >*请输入正确的身份证号，否则不能获取到个人相关信息</div>
                    <div className="loginButton"><Button type="primary" onClick={this.bangding}>绑定</Button><WhiteSpace /></div>
                </div>
                <div className="aclass">
                    <a href="https://beian.miit.gov.cn/" style={{ color: "#c1c1c1" }}>新ICP备2021000675号-1</a>
                </div>
            </div>
        );
    }
}

