import './clph.css'
import React, { Component } from 'react'
import { BASE_URL, Axios } from '../../../utils/url'
import { NavBar, Icon, List, Modal, Radio, Button, InputItem, Toast,ActionSheet} from 'antd-mobile';
const isIPhone = new RegExp('\\biPhone\\b|\\biPod\\b', 'i').test(window.navigator.userAgent);
let wrapProps;
if (isIPhone) {
  wrapProps = {
    onTouchStart: e => e.preventDefault(),
  };
}
const Item = List.Item;
const Brief = Item.Brief;
const RadioItem = Radio.RadioItem;
export default class Cljf extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dsplist: [],
            isLoaded: false,
            modal2: false,
            modal: false,
            value: "",
            phxx: "",
            ph: "",
            item: "",
            ryxm: "",
            sfzh: "",
            phone: ""
        }
    }
    componentDidMount = () => {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        this.setState({
            ryxm: userInfo.ryxm,
            sfzh: userInfo.sfzh,
            phone: userInfo.rysx20120716p0048
        })
        this.getList();

    }

    async getList() {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        const url = BASE_URL + 'bsqjlController/getphList';
        var params = new URLSearchParams();
        params.append("sfzh", userInfo.sfzh);
        await Axios({
            method: 'post',
            url: url,
            data: params
        }).then((res) => {
            console.log("123",res);
            if (res.status === 200) {
                this.setState({
                    dsplist: res.data.data,
                    isLoaded: true
                });
            }
        });
    }

    handleDjf = (item) => {
        if (item.zt == "未排号") {
            this.setState({
                modal2: true,
                item: item
            });
        } else if (item.zt == "排号中") {
            this.setState({
                item: item
            });
            this.getPhxx(item.cph);
        } else if (item.zt == "已入库") {
            this.showActionSheet(item)
        }

    }
    showActionSheet = (item) => {
        console.log(item)
        const BUTTONS = ['权限变更', '取消'];
        ActionSheet.showActionSheetWithOptions({
          options: BUTTONS,
          cancelButtonIndex: BUTTONS.length - 1,
          destructiveButtonIndex: BUTTONS.length - 2,
          // title: 'title',
          message: '请选择您的操作',
          maskClosable: true,
          'data-seed': 'logId',
          wrapProps,
        },
        (buttonIndex) => {
            console.log(buttonIndex);
          if(buttonIndex ===0){
            this.props.history.push({ pathname : "./clbgEitder" , query : { clxx : item }});
          }
        });
      }
    // 获取已经排号车辆信息
    getPhxx = (cph) => {
        const url = BASE_URL + 'bclphxxController/getphxx';
        var params = new URLSearchParams();
        params.append("cph", cph);
        Axios({
            method: 'post',
            url: url,
            data: params
        }).then((res) => {
            this.setState((state, props) => {
                return {
                    phxx: res.data.phxx,
                    ph: "您前面还有" + res.data.ph + "人",
                    modal: true
                }
            },
                () => {

                }
            )
        });
    }
    //关闭弹出窗
    onClose = key => () => {
        this.setState({
            [key]: false,
        });
    }
    // 循环展示列表
    setMapList = () => {
        if (!this.state.isLoaded) {
            return null;
        } else {
            return this.state.dsplist.map((item, key) => (
                <Item multipleLine extra={item.crzlx} extra={item.wz} key={key} onClick={() => this.handleDjf(item)}>
                    {item.cph} <Brief>{item.xm}</Brief>
                    <Brief>{item.zt}</Brief>
                </Item>
            ))
        }
    }
    // 未排号车辆排号
    setPh = () => {
        const url = BASE_URL + 'bclphxxController/save';
        var params = new URLSearchParams();
        params.append("sqid", this.state.item.sqid);
        params.append("crzlx", this.state.item.wz);
        params.append("cph", this.state.item.cph);
        params.append("phck", this.state.value);
        params.append("yhxm", this.state.sqr);
        params.append("yhid", this.state.sfzh);
        params.append("hhid", localStorage.getItem("bshtoken"));
        Toast.loading("加载中...", 0, null, true);
        Axios({
            method: 'post',
            url: url,
            data: params
        }).then((res) => {
            Toast.hide();
            window.location.reload();
        });
        this.setState({
            modal2: false,
        });
    }
    //取消排号
    qxph = () => {
        console.log(this.state.item.phid)
        const url = BASE_URL + 'bclphxxController/deletes';
        var params = new URLSearchParams();
        params.append("ids", this.state.item.phid);
        Toast.loading("加载中...", 0, null, true);
        Axios({
            method: 'post',
            url: url,
            data: params
        }).then((res) => {
            Toast.hide();
            window.location.reload();
        });
        this.setState({
            modal: false
        });
    }
    // 获取选择车库信息
    onChange = (value, label) => {
        this.setState({
            value,
        });
    };
    render() {
        const { value } = this.state;
        const data = [
            { value: "一区地下停车场", label: '一区地下停车场' },
            { value: "五区地下停车场", label: '五区地下停车场' },
        ];
        return (
            <div style={{ marginTop: ".89rem" }}>
                {/* 导航栏#0F84D6 */}
                <NavBar
                    mode="dark"
                    icon={<Icon type="left" />}
                    onLeftClick={() => this.props.history.go(-1)}
                >地库排号</NavBar>

                <div className="divmagin">

                    <List renderHeader={() => ''} >
                        {this.setMapList()}
                    </List>
                    {/* 选择排号车库 */}
                    <Modal
                        popup
                        visible={this.state.modal2}
                        onClose={this.onClose('modal2')}
                        animationType="slide-up"
                        afterClose={() => { console.log('afterClose'); }}
                    >
                        <List renderHeader={() => '选择需要排号车库'}>
                            {data.map(i => (
                                <RadioItem key={i.value} checked={value === i.value} onChange={() => this.onChange(i.value, i.label)}>
                                    {i.label}
                                </RadioItem>
                            ))}
                            <Button type="primary" onClick={this.setPh}>确认选择</Button>
                        </List>
                    </Modal>
                    {/* 展示排号信息 */}
                    <Modal
                        popup
                        visible={this.state.modal}
                        onClose={this.onClose('modal')}
                        animationType="slide-up"
                        afterClose={() => { console.log('afterClose'); }}
                    >
                        <List renderHeader={() => '排号信息'} >
                            <InputItem
                                type="money"
                                value={this.state.phxx.cph}
                                editable={false}
                            ><div>车牌号</div></InputItem>
                            <InputItem
                                type="money"
                                value={this.state.phxx.dqzt}
                                editable={false}
                            ><div>排号状态</div></InputItem>
                            <InputItem
                                type="money"
                                value={this.state.phxx.phck}
                                editable={false}
                            ><div>排号车库</div></InputItem>

                            <InputItem
                                type="money"
                                value={this.state.ph}
                                editable={false}
                            ><div>等待人数</div></InputItem>
                        </List>
                        <div className="dban">
                            <Button type="warning" inline style={{ marginRight: '4px' }} onClick={this.qxph}>取消排号</Button>
                            <Button type="primary" inline style={{ marginRight: '4px' }} onClick={this.onClose('modal')}>关闭</Button>
                        </div>

                    </Modal>
                </div>

            </div>
        )
    }
}
