import './download.css'
import React, { Component } from 'react'
import { NavBar,Icon,List, Modal,Toast,Card, WingBlank, WhiteSpace } from 'antd-mobile';
import { WY_URL, Axios } from '../../../utils/url';
import fj from '../../../assets/images/fj.png'
const Item = List.Item;
const Brief = Item.Brief;
export default class Yktbksq extends Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [{fjmc: "一卡通申请单附件",fjbm: "yktsqfj"},{fjmc: "车辆申请单附件",fjbm: "clsqdfj"}],
            isLoaded: false,

        }
    }
    componentDidMount = () => {

    }





    handleJump=(item)=>{
        window.open( WY_URL+'yktsqxx/download?fjlx='+item.fjbm)
    }




    setMapList = () => {

            return this.state.list.map((item, key) => (
                <Item
                arrow="horizontal"
                thumb={fj}
                multipleLine
                onClick={() => {this.handleJump(item)}}
                >
                {item.fjmc} <Brief>点击下载</Brief>
                </Item>
            ))
    }
    render() {
        return (
            <div style={{ marginTop: ".89rem" }}>
            {/* 导航栏#0F84D6 */}
            <NavBar
              mode="dark"
              icon={<Icon type="left" />}
             onLeftClick={() => this.props.history.goBack()}
            >附件模板下载</NavBar>
            <div className="divmagin">
                    {this.setMapList()}
                    <div className="scfj">电脑端下载说明</div>
                    <p style={{color:'#C0C0C0',lineHeight:'25px',fontSize:'15px',margin:'10px 10px 10px 10px'}}>登录油田首页-鼠标指到左下角“相关连接”-选择“宝石花物业”-在页面的左下方找到“部门制度表单”，选择“客户服务项目部”，即可看到相关表单下载。</p>
                </div>
            </div>
        )
    }
}
