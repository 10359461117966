import './ygkgs.css'
import React, { Component } from 'react'
import { YGK_URL, FORMAT, Axios } from '../../../utils/url'
import ygk from '../../../assets/images/ygk.png'
import { NavBar, Icon, List, Toast, Modal, InputItem, Button ,WingBlank,WhiteSpace,Card} from 'antd-mobile';
const Item = List.Item;
const Brief = Item.Brief;
export default class Ygkgs extends Component {
    constructor(props) {
        super(props)

        this.state = {
            modal2: false,
            text: "",
            qbList: [{ value: 0, label: '运输事务部' },
            { value: 1, label: '机关' }],
            dqzt: ""
        }
    };
    //进页面加载
    componentDidMount = () => {

        // console.log(localStorage.getItem("userInfo"));
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        this.setState({
            sfzh: userInfo.sfzh,
        })
        this.getClglList(userInfo.sfzh);
    }
    //获取列表
    async getClglList(sfzh) {
        const url = YGK_URL + 'ygkgsController/getRyList';
        var params = new URLSearchParams();
        params.append("sfzh",sfzh);
        params.append("hhid", localStorage.getItem("bshtoken"));
        //获取人员身份证号并传递参数
        Toast.loading("加载中...", 0, null, true);
        await Axios({
            method: 'post',
            url: url,
            data: params
        }).then((res) => {
            Toast.hide();
            console.log(res)
            console.log(res.data)
            if (res.status === 200) {
                if(res.data.length > 0){
                    if(res.data[0].lxsjh){
                        var tel = res.data[0].lxsjh;
                        var reg = /^(\d{3})\d{4}(\d{4})$/;

                        tel = tel.replace(reg, "$1****$2");

                        console.log(tel);
                    }
                    this.setState((state, props) => {
                        return {
                            rylist: res.data,
                            isLoaded: true,
                            ryxh: res.data[0].ryxh,
                            lxsjh:tel

                        }
                    },
                        () => {
                            const url = YGK_URL + 'ygkgsController/getQbList';
                            var params = new URLSearchParams();
                            params.append("ryxh", this.state.ryxh);
                            params.append("hhid", localStorage.getItem("bshtoken"));
                            Axios({
                                method: 'post',
                                url: url,
                                data: params
                            }).then((res) => {
                                Toast.hide();
                                console.log(res)
                                let zhxh1 = ""
                                if (res.status === 200) {

                                    if (res.data.length > 0) {

                                        for (var i = 0; i < res.data.length; i++) {
                                            console.log(res.data[i].zhxh)
                                            zhxh1 += res.data[i].zhxh + ",";
                                        }
                                    }
                                    console.log(zhxh1)
                                    this.setState((state, props) => {
                                        return {
                                            qbList: res.data,
                                            grzh: res.data[0].grzh,
                                            zhxh: zhxh1,
                                            dqzt: res.data[0].dqztmc
                                        }
                                    },
                                        () => {


                                        })
                                }
                            });

                        })
                }else{
                    Toast.info("暂未查询到您的员工卡")
                }


            }
        });
    }
    setMapList = () => {
        if (!this.state.isLoaded) {
            return null;
        } else {
            return this.state.rylist.map((item, key) => (
                <WingBlank size="lg" key={key}>
                <WhiteSpace size="lg" />
                <Card onClick={()=>this.handleJump(item)}>
                    <Card.Header
                        title={item.ryxm}
                        thumb={ygk}
                        extra={<span>{item.rybh}</span>}
                    />
                    <Card.Body>
                        <div>{item.zhlxmc}</div>
                    </Card.Body>
                    <Card.Footer content={'联系手机号：' + this.state.lxsjh }  />
                </Card>
                <WhiteSpace size="lg" />
            </WingBlank>
            ))
        }
    }
    handleJump = (item) => {
        this.setState({
            modal2: true,
        });
    }
    guashi = () => {
        const url = YGK_URL + 'ygkgsController/zhxxReportLoss';
        var params = new URLSearchParams();
        params.append("ryxh", this.state.ryxh);
        params.append("grzh", this.state.grzh);
        params.append("zhxh", this.state.zhxh);
        // params.append("hhid", localStorage.getItem("bshtoken"));
        Modal.alert('您确定挂失吗', '挂失后只能去大厅去补卡哦~', [
            { text: '取消', onPress: () => new Promise((resolve) => {
              //刷新页面

              this.componentDidMount();
              setTimeout(resolve, 100);

            }), },

            {
              text: '确定',
              onPress: () =>
                new Promise((resolve) => {
                  //跳转到登录

                  // Toast.info('onPress Promise', 1);
                  setTimeout(resolve, 1000);
                  Axios({
                    method: 'post',
                    url: url,
                    data: params
                }).then((res) => {
                    Toast.hide();
                    console.log(res)
                    if (res.status === 200 ) {

                        this.setState((state, props) => {
                            return {
                                dqzt: false,
                                modal2:false
                            }
                        },
                            () => {
                                this.onClose('modal2')
                                Modal.alert('挂失成功', '是否去补卡', [
                                    { text: '取消', onPress: () => new Promise((resolve) => {
                                      //刷新页面

                                      this.componentDidMount();
                                      setTimeout(resolve, 100);

                                    }), },

                                    {
                                      text: '确定',
                                      onPress: () =>
                                        new Promise((resolve) => {
                                          //跳转到登录
                                          this.props.history.push({ pathname : "./Ygkbksq" });
                                          // Toast.info('onPress Promise', 1);
                                          setTimeout(resolve, 1000);
                                        }),
                                    },
                                  ])
                            })

                    }
                });
                }),
            },
          ])

    }
    onClose = key => () => {
        this.setState({
            [key]: false,
        })

    }

    SetMapDw = (e) => {
        // if(this.state.dqztmc ==="挂失"){
        //     this.setState({
        //         dqzt:false
        //     })
        // }
        console.log(this.state.qbList)
        if (this.state.qbList.length > 0) {
            return this.state.qbList.map((item, key) => (
                <Item multipleLine extra={item.zhlxmc} key={key} >
                    余额：{FORMAT.number_format(item.ye/100, 2)+"元"} <Brief>姓名：{item.ryxm}</Brief>
                    <Brief>状态：{item.dqztmc}</Brief>
                </Item>

            ))
        }
        this.forceUpdate()
    }
    setButton = (e) => {
        console.log()
        if (this.state.dqzt !== "挂失") {
            return (
                <List.Item>
                    <Button type="primary" onClick={this.guashi}>挂失</Button>
                </List.Item>

            )
        }




    }
    render() {
        console.log(this.state.dqzt)
        return (
            <div style={{ marginTop: ".89rem" }}>
                {/* 导航栏#0F84D6 */}
                <NavBar
                    mode="dark"
                    icon={<Icon type="left" />}

                    onLeftClick={() => this.props.history.go(-1)}
                >员工卡挂失</NavBar>
                <div className="divmagin">
                    {this.setMapList()}

                </div>
                <div>
                    <Modal
                        popup
                        visible={this.state.modal2}
                        onClose={this.onClose('modal2')}
                        animationType="slide-up"
                        style={{
                            height: "520px",
                            overflow: "auto"
                        }}

                    >

                        <List renderHeader={() => <div>员工卡明细</div>} className="popup-list">

                            {this.SetMapDw(this.state.text)}
                            {this.setButton()}

                        </List>
                    </Modal>
                </div>
            </div>
        )
    }
}
