import './index.css'
import ygk from '../../assets/images/ygk.png'
import React, { Component } from 'react'
import { BASE_URL, YGK_URL, YKT_URL, Axios } from '../../utils/url'
import { Tabs, NavBar, NoticeBar, List, WingBlank, Card, WhiteSpace, Button, Toast, ImagePicker, Icon, Modal } from 'antd-mobile';
const Item = List.Item;
const Brief = Item.Brief;
const nowTimeStamp = Date.now();
const now = Date.now();
// Make sure that in `time` mode, the maxDate and minDate are within one day.
const minDate = new Date(nowTimeStamp);
const maxDate = new Date(nowTimeStamp + 86400000);
var tag = '';
const tabs = [
    { title: '预约信息' },
    { title: '验证信息' },
];
export default class crcsyz extends Component {
    constructor(props) {
        super(props)
        // const cs = this.props.location.query.cardList;
        if(!window.location.href.indexOf("&sjs")!=-1){
            window.location.href = window.location.href+"&sjs="+now;
        }
        const openid = this.props.location.search;
        if (openid.length > 0) {
            var open = openid.split("&");
            var openidArr = open[0].split("=");
            var openid1 = openidArr[1];
            // var tagArr = open[1].split("=");
            // tag = tagArr[1];

            var params = new URLSearchParams();
            localStorage.setItem("openID", openid1);
            params.append('openid', openid1);
            Axios({
                method: 'post',
                url: BASE_URL+"/bRyzcController/getUserInfo",
                data: params
            }).then((res) => {
                localStorage.setItem("userInfo", JSON.stringify(res.data.userInfo));
                const userInfo = JSON.parse(localStorage.getItem("userInfo"));
                this.setState({
                    ryxm: userInfo.ryxm,
                    sqdw: userInfo.rysx20120716p0026,
                    sfzh: userInfo.sfzh,
                    zz: userInfo.rysx20120716p0051,
                    ektbh: userInfo.rysx20120716p0046,
                    sqrid: userInfo.ryid,
                    phone: userInfo.rysx20120716p0048,
                    userInfo: userInfo,
                    dwbm: userInfo.dwbm,
                });
            });
        }
        this.state = {
            wyyList: [],
            yyyList: []
        }
    }

    //进页面加载
    componentDidMount = () => {
        this.forceUpdate();
        const openids = this.props.location.search;
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        if (!openids.length > 0) {
            this.setState({
                ryxm: userInfo.ryxm,
                sqdw: userInfo.rysx20120716p0026,
                sfzh: userInfo.sfzh,
                zz: userInfo.rysx20120716p0051,
                ektbh: userInfo.rysx20120716p0046,
                sqrid: userInfo.ryid,
                phone: userInfo.rysx20120716p0048,
                userInfo: userInfo,
                dwbm: userInfo.dwbm,
            });
        }
        this.getList(userInfo.sfzh);
    }
    //预约信息
    getList = (e) => {
        const url = BASE_URL + 'crcsyyOrder/getList';
        var params = new URLSearchParams();
        params.append('sfz', e);
        Axios({
            method: 'post',
            url: url,
            data: params,
        }).then((res) => {
            if (res.data.length > 0) {
                let wyylist = [];
                let yyylist = [];
                for (var a = 0; a < res.data.length; a++) {
                    if (res.data[a].record_status === 1) {
                        wyylist.push(res.data[a]);
                    } else if (res.data[a].record_status === 0) {
                        yyylist.push(res.data[a]);
                    }
                }
                this.setState({
                    wyyList: wyylist,
                    yyyList: yyylist
                }, () => {
                    // this.getYycount();
                    this.setMapWyzList();
                });

            }
        });
    }
    setMapWyzList = () => {
        const list = this.state.wyyList
        if (list.length > 0) {
            return list.map((item, key) => (
                <div>
                    {/* <Item multipleLine extra={item.place_name} wrap={true}>
                        {item.create_user_name} <Brief >{item.order_date}</Brief><Brief>{item.start_time}-{item.end_time}</Brief>
                        There may have water ripple effect of material if you set the click event.
                    </Item>
                    <div className='tishi'>第<span className="xianshi">{item.dqpx}</span>位成功预约,请在<span className="xianshi">{item.check_start_time}</span>-<span className="xianshi">{item.check_end_time}</span>到<span className="xianshi">{item.place_name}</span>内验证，在此时间之外无效</div> */}
                    <WingBlank size="lg">
                        <WhiteSpace size="lg" />
                        <Card>
                            <Card.Header
                                title={item.create_user_name}
                                // thumb={ygk}
                                extra={<span>{item.place_name}</span>}
                            />
                            <Card.Body>
                                <div style={{padding:'5px'}}>{item.order_date}&nbsp;&nbsp;&nbsp;{item.start_time}-{item.end_time}</div>
                            <div className='tishi' style={{padding:'5px'}}>第<span className="xianshi">{item.dqpx}</span>位成功预约,请在<span className="xianshi">{item.check_start_time}</span>-<span className="xianshi">{item.check_end_time}</span>到<span className="xianshi">{item.place_name}</span>内验证，在此时间之外无效</div>
                            </Card.Body>
                        </Card>
                        <WhiteSpace size="lg" />
                    </WingBlank>
                </div>

            ))
        }
    }
    setMapYyzList = () => {
        const list = this.state.yyyList
        if (list.length > 0) {
            return list.map((item, key1) => (
                <Item multipleLine extra={item.place_name} >
                    {item.create_user_name} <Brief>{item.order_date}</Brief><Brief>{item.start_time}-{item.end_time}</Brief>
                </Item>
            ))
        }
    }
    handleSq = () => {
        this.props.history.push('./crcssq');
    }
    render() {
        return (
            <div style={{ marginTop: ".89rem" }}>
                {/* 导航栏#0F84D6 */}
                <NavBar
                    mode="dark"
                    rightContent={[
                        <div onClick={this.handleSq} style={{ fontSize: '17px' }}><Button type="ghost" inline size="small" style={{
                            marginRight: '4px', color: 'white',
                            border: '1px solid white',
                            fontSize: '16px'
                        }}>我要预约</Button></div>
                    ]}
                ></NavBar>
                <div style={{ marginTop: "50px" }}>
                    <NoticeBar marqueeProps={{ loop: true, style: { padding: '0 7.5px' } }}>
                        请在当天开始前二十分钟和开始后十分钟指定场所验证，过期失效（全天开放的场所请忽略此规则）
                    </NoticeBar>
                    <Tabs tabs={tabs} initialPage={0} animated={false} useOnPan={false} >
                        <div style={{ alignItems: 'center', justifyContent: 'center', backgroundColor: '#fff' }}>
                            {this.setMapWyzList()}
                        </div>
                        <div style={{ alignItems: 'center', justifyContent: 'center', backgroundColor: '#fff' }}>
                            {this.setMapYyzList()}
                        </div>
                    </Tabs>
                </div>

            </div>
        )
    }
}
