import './ygkgbshow.css'
import React, { Component } from 'react'
import { NavBar,Icon,List,InputItem,ImagePicker,Toast,Modal,Button} from 'antd-mobile';
import { YGK_URL, Axios } from '../../../utils/url';
const Item = List.Item;
const Brief = Item.Brief;
export default class Ygkgbfjf extends Component {
    constructor(props) {
        super(props);
        const bkid = this.props.location.query.bkid;
        this.state = {
            bkid:bkid,
            ryxh:'',
            rybh:'',
            ryxm:'',
            xbmc:'',
            rylxmc:'',
            mzmc:'',
            zjbh:'',
            lxsjh:'',
            list:{},
            qblist:[],
            isLoaded:false,
            files:[],
            imglist:[],

            visible:false,
            previewImage:'',
            current:90,
            transStyle:''

        }
    }




    componentDidMount = () => {
        this.getList();
        this.getimgList();
    }

    async getimgList() {
        const url = YGK_URL+'fjuplode/page';
        var params = new URLSearchParams();
        params.append("dyid", this.state.bkid);
        await Axios({
            method: 'post',
            url: url,
            data: params
        }).then((res) => {
            console.log(res.data);
            if (res.status === 200) {
                this.setState({
                  files:res.data
                });
            }
        });
    }



    async getList() {
      console.log("执行获取数据");

        const url = YGK_URL+'ygkbksq/showmx';
        var params = new URLSearchParams();
        params.append("bkid", this.state.bkid);
        await Axios({
            method: 'post',
            url: url,
            data: params
        }).then((res) => {
            console.log(res.data);
            if (res.status === 200) {
                this.setState({
                    list:res.data.records[0]
                });
                console.log(this.state.list.ryxh);
            }
        });
    }

    async getxqList() {
          const url = YGK_URL+'bkjlzh/page';
          var params = new URLSearchParams();
          params.append("bkid", this.state.bkid);
          await Axios({
              method: 'post',
              url: url,
              data: params
          }).then((res) => {
              console.log(res.data);
              if (res.status === 200) {
                  this.setState({
                    qblist:res.data.records,
                    isLoaded:true
                  });
              }
          });
      }

    //   setMapList = () => {
    //     if (!this.state.isLoaded) {
    //         return null;
    //     } else {
    //         return this.state.qblist.map((item, key) => (
    //             <InputItem
    //             value={item.ye}
    //             ref={el => this.labelFocusInst = el}
    //             labelNumber="7"
    //             editable
    //         ><div >{item.zhlxmc}</div></InputItem>
    //         ))
    //     }
    // }


  imgonclick=(index,files) =>{
    this.setState({
      previewImage: files[index].url,
      visible: true
  });
  }
  onClose=() =>{
    this.setState({
      visible: false
  });
  }

    render() {
        const { RYBH,RYXM,XBMC,YLXMC,MZMC,ZJBH,LXSJH,files} = this.state

        return (
            <div style={{ marginTop: ".89rem" }}>
            {/* 导航栏#0F84D6 */}
            <NavBar
              mode="dark"
              icon={<Icon type="left" />}
             onLeftClick={() => this.props.history.goBack()}
            >员工卡补卡申请详情</NavBar>
             <form onSubmit={this.handleSubmit}>
             <List renderHeader={() => '申请人基本信息'} >
             <div className="scfj">申请人基本信息</div>
             <InputItem
              value={this.state.list.ryxm}
              ref={el => this.labelFocusInst = el}
              editable
            ><div >申请人姓名</div></InputItem>

            <InputItem
              value={this.state.list.rybh}
              ref={el => this.labelFocusInst = el}
              editable
            ><div >人员编号</div></InputItem>

            <InputItem
              value={this.state.list.xb}
              ref={el => this.labelFocusInst = el}
              editable
            ><div >性别</div></InputItem>

            <InputItem
              value={this.state.list.lxmc}
              ref={el => this.labelFocusInst = el}
              editable
            ><div >类型名称</div></InputItem>

            <InputItem
              value={this.state.list.mz}
              ref={el => this.labelFocusInst = el}
              editable
            ><div >民族</div></InputItem>

            <InputItem
              value={this.state.list.zjbh}
              ref={el => this.labelFocusInst = el}
              editable
            ><div >证件编号</div></InputItem>

            <InputItem
              value={this.state.list.lxdh}
              ref={el => this.labelFocusInst = el}
              editable
            ><div >联系手机号</div></InputItem>
            <InputItem
              value={this.state.list.thyj}
              editable
            ><div >意见</div></InputItem>
            {/* <div className="scfj">余额(元)</div>
            {this.setMapList()} */}
            <div className="scfj">已上传附件</div>
            <ImagePicker
              files={files}
              onChange={this.onChange}
              onImageClick={this.imgonclick}
              selectable={files.length < 4}
              multiple={this.state.multiple}
              selectable={false}
            />
            <Modal
                    visible={ this.state.visible }
                    transparent
                    footer={[{ text: '关闭', onPress: () => { console.log('ok'); this.onClose(); } }]}
                >
                    <div style={{ marginTop:50, transform:this.state.transStyle, display:'flex', alignItems:'center'}}>
                        <img
                            alt="example"
                            style={{width: '100%',height:'100%' }}
                            src={this.state.previewImage}
                        />
                    </div>
                    {/* <div style={{clear:'both'}} /> */}
                </Modal>
            </List>

             </form>

            </div>
        )
    }
}
