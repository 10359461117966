import React, { Component } from 'react'
import { BASE_URL, YGK_URL, Axios } from '../../utils/url'
import { NavBar, InputItem, List, DatePicker, Picker, Radio, Button, Toast, ImagePicker, Icon, Modal } from 'antd-mobile';

const nowTimeStamp = Date.now();
const now = new Date(nowTimeStamp);

// Make sure that in `time` mode, the maxDate and minDate are within one day.
const minDate = new Date(nowTimeStamp);
const maxDate = new Date(nowTimeStamp + 86400000);
var xzsj = '';
export default class crcssq extends Component {
    constructor(props) {
        super(props)
        this.state = {
            yycsList: [],
            sjdList: [],
            ctData: [],
            date: '',
            sjd: '',
            yycs: '',
            yycsmc: '',
            dqzt: '',
            day: '',
            gywc: '',
            gyz: '',
            gyzc: '',
            note: '',
            year_month: '',
            yysjwc: '',
            yysjz: '',//早餐
            yysjzc: '',//中餐
            zcygk: '',
            zcykt: '',
            ctxxList: '',
            ctmc: '',
            yyrq: '',
            hasErrorPhone: false,
        }
    }

    //进页面加载
    componentDidMount = () => {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        this.setState({
            ryxm: userInfo.ryxm,
            sqdw: userInfo.rysx20120716p0026,
            sfzh: userInfo.sfzh,
            zz: userInfo.rysx20120716p0051,
            ektbh: userInfo.rysx20120716p0046,
            sqrid: userInfo.ryid,
            phone: userInfo.rysx20120716p0048,
            userInfo: userInfo,
            dwbm: userInfo.dwbm,
        });
        this.getCsmc();
    }

    //获取场所名称
    getCsmc = () => {
        const url = BASE_URL + 'crcsyyInfo/getCsmc';
        var params = new URLSearchParams();
        Axios({
            method: 'post',
            url: url,
            data: params,
        }).then((res) => {
            if (res.data.length > 0) {
                this.setState({
                    yycsList: res.data
                });
            }
        });
    }
    //获取场所时间段
    getSjd = (e) => {
        const url = BASE_URL + 'crcsyyTime/getsjd';
        var params = new URLSearchParams();
        params.append('csmc', e);
        Axios({
            method: 'post',
            url: url,
            data: params,
        }).then((res) => {
            if (res.data.length > 0) {
                var date = new Date(this.state.date);
                var year = date.getFullYear();
                /* 在日期格式中，月份是从0开始的，因此要加0
                * 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
                * */
                // 拼接
                var hour = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
                var minute = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();

                let hour_min = hour.toString() + minute.toString();
                //当前时间
                var dateNow = new Date();
                var yearNow = dateNow.getFullYear();
                /* 在日期格式中，月份是从0开始的，因此要加0
                * 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
                * */
                var monthNow = dateNow.getMonth() + 1 < 10 ? "0" + (dateNow.getMonth() + 1) : dateNow.getMonth() + 1;
                // 拼接时间
                var dayNow = dateNow.getDate() < 10 ? "0" + dateNow.getDate() : dateNow.getDate();
                let year_monthNow = yearNow + monthNow + dayNow;
                //判断选中时间是否是当前时间
                //如果成立 筛选出当前时间段大于数据库维护的时间段重新组织数据
                if (year_monthNow === xzsj) {
                    this.state.sjdList.splice(0)
                    for (var a = 0; a < res.data.length; a++) {
                        if(res.data[a].start_time.replace(':', '')!='0000'){
                            if (hour_min < res.data[a].start_time.replace(':', '')) {
                                let zcArr = {}
                                zcArr['label'] = res.data[a].label;
                                zcArr['value'] = res.data[a].value;
                                zcArr['max_count'] = res.data[a].max_count;
                                zcArr['check_start_time'] = res.data[a].check_start_time;
                                zcArr['check_end_time'] = res.data[a].check_end_time;
                                this.state.sjdList.push(zcArr)
                            }
                        }else{
                            this.setState({
                                sjdList:res.data
                            })
                        }
                    }
                }else{
                    this.setState({
                        sjdList:res.data
                    })
                }
            }
        });
        //查询场所人数
    }
    //时间选择
    chooseDate = (e) => {
        if (this.state.yycs) {
            console.log(e)
            var date = new Date(e);
            var year = date.getFullYear();
            /* 在日期格式中，月份是从0开始的，因此要加0
            * 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
            * */
            var month = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
            var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
            // 拼接
            xzsj = year + month + day;
            let year_month = year + '-' + month + '-' + day;
            console.log(year_month)
            this.setState({
                date: e,
                sjd: '',
                yyrq: year_month
            }, () => {
                this.getYzcs()
                //
            })
        } else {
            Toast.fail('请先选择预约场所')
        }
    }
    //选择时间点
    chooseSjd = (e) => {
        if (this.state.date) {
            const list = this.state.sjdList
            for (var item in list) {
                if (list[item].value == e) { //item 表示Json串中的属性，如'name'
                    var jValue = list[item];//key所对应的value
                    // console.log(jValue.label);
                    var labelVal = jValue.label;
                    var maxCount = jValue.max_count;
                    var kssj = labelVal.split('-')[0];
                    var jssj = labelVal.split('-')[1];
                    var check_start_time = jValue.check_start_time;
                    var check_end_time = jValue.check_end_time;
                    this.setState({
                        sjdid: jValue.label,
                        kssj: kssj,
                        jssj: jssj,
                        maxCount:maxCount,
                        check_start_time:check_start_time,
                        check_end_time:check_end_time
                    }, () => {
                        this.getCount(maxCount);
                    });
                }
            }
            this.setState({
                sjd: e
            })
        } else {
            Toast.fail("请先选择预约日期")
        }
    }
    //场所选择
    chooseYycs = (e) => {
        const list = this.state.yycsList
        for (var item in list) {
            if (list[item].value == e) { //item 表示Json串中的属性，如'name'
                var jValue = list[item];//key所对应的value
                // console.log(jValue.label);
                // this.state.syrlxText = jValue.label;
                this.setState({
                    yycsmc: jValue.label,
                    iscs:jValue.iscs,
                    yycs: e,
                    date:'',
                    sjd:''
                })

            }
        }
    }
    //验证场所
    getYzcs = () =>{
        const url = BASE_URL + 'crcsyyOrder/getYzcs';
        var params = new URLSearchParams();
        params.append('sfz', this.state.sfzh);//TYG10_12
        params.append('order_data', this.state.yyrq);
        params.append('iscs', this.state.iscs);
        Axios({
            method: 'post',
            url: url,
            data: params,
        }).then((res) => {
            if(res.data.length>0 && this.state.iscs==='1'){
                Toast.fail('每人一天只能预约一次超市')
                this.setState({
                    yycs:'',
                    date:''
                })
            }else{
                this.getSjd(this.state.yycs);
            }
        });
    }
    //查询场所在该时间段是否超过最大次数
    getCount = (maxCount) => {
        const url = BASE_URL + 'crcsyyOrder/getCount';
        var params = new URLSearchParams();
        params.append('time_id', this.state.sjd);//TYG10_12
        params.append('order_data', this.state.yyrq);
        Axios({
            method: 'post',
            url: url,
            data: params,
        }).then((res) => {
            if (res.data.length > 0) {
                if(maxCount<=res.data[0].zts){
                    Toast.fail('该场所当前时间段预约人数已上限，请更换时间段预约');
                    this.setState({
                        sjd:''
                    })
                }
            }
            this.isYy();
        });
    }
    isYy = () =>{
        //判断当前人员在该时间段是否有预约
        const url1 = BASE_URL + 'crcsyyOrder/getDqyysj';
        var params1 = new URLSearchParams();
        params1.append('order_data', this.state.yyrq);
        params1.append('sfz', this.state.sfzh);
        params1.append('Place_id', this.state.sjd);
        Axios({
            method: 'post',
            url: url1,
            data: params1,
        }).then((res) => {
            if (res.data.length > 0) {
                this.setState({
                    sjd:''
                })
                Toast.fail('您已经在该场所当前时间段预约过了~')
            }
        });
    }
    //绑定手机号phone的onchange事件
    onChangePhone = (value) => {
        const str = value.replace(/\s*/g, "");
        console.log(str);
        if (value.replace(/\s/g, '').length != 11) {
            this.setState({
                hasErrorPhone: true,
            });
        } else {
            this.setState({
                hasErrorPhone: false,
            });
        }
        this.setState({
            phone: str,
        });

    }
    //手机输入错误提示
    onErrorClickPhone = () => {
        if (this.state.hasErrorPhone) {
            Toast.info('请输入正确的手机号');
        }
    }
    handleSubmit = e => {
        e.preventDefault()
        if (this.state.ryxm && this.state.sfzh && this.state.phone && this.state.date && this.state.sjd && this.state.yycs &&!this.state.hasErrorPhone) {
            const url = BASE_URL + 'crcsyyOrder/saveData';
            let params = new FormData()
            params.append('openid', localStorage.getItem("openID"));
            params.append('xm', this.state.ryxm);
            params.append('sfz', this.state.sfzh);
            params.append('phone', this.state.phone);
            params.append('yycs', this.state.yycsmc);
            params.append('yyrq', this.state.yyrq);
            params.append('sjd', this.state.sjd);
            params.append('Place_id', this.state.yycs);
            params.append('kssj', this.state.kssj);
            params.append('jssj', this.state.jssj);
            params.append('iscs', this.state.iscs);
            params.append('check_start_time', this.state.check_start_time);
            params.append('check_end_time', this.state.check_end_time);
            Toast.loading("加载中...", 0, null, true);
            Axios({
                headers: { 'Content-Type': 'multipart/form-data' },
                method: 'post',
                url: url,
                data: params,
                timeout: 900000, //这个就是重点
                //这部分非常重要，否则formdata会被转格式
                transformRequest: [function () {
                    return params;
                }],
            }).then((res) => {
                Toast.hide();
                if (res.data.flag && res.data.msg == "预约成功!") {
                    // Toast.info(res.data.msg)
                    Toast.info('预约成功', 2, null, false);
                    //
                    let that = this;
                    that.props.history.go(-1)
                }
            });
        } else {
            Toast.fail('请完善表单之后再提交')
        }
    }

    render() {
        return (

            <div style={{ marginTop: ".89rem" }}>
                {/* 导航栏#0F84D6 */}
                <NavBar
                    mode="dark"
                    icon={<Icon type="left" />}
                    onLeftClick={() => this.props.history.go(-1)}

                >场所预约</NavBar>

                <form onSubmit={this.handleSubmit}>
                    <List renderHeader={() => '预约信息'} className="my-list">
                        <Picker
                            data={this.state.yycsList}
                            cols={1}
                            title="请选择预约场所"
                            extra="请选择预约场所"
                            onChange={this.chooseYycs}
                            className="forss"
                            value={this.state.yycs}
                        >
                            <List.Item arrow="horizontal"><span className="error">*</span>预约场所</List.Item>
                        </Picker>
                        <DatePicker
                            mode="date"
                            title="请选择预约日期"
                            extra="请选择预约日期"
                            value={this.state.date}
                            onChange={this.chooseDate}
                            minDate={minDate}
                            maxDate={maxDate}
                        >
                            <List.Item arrow="horizontal"><span className="error">*</span>预约日期</List.Item>
                        </DatePicker>
                        <Picker
                            data={this.state.sjdList}
                            cols={1}
                            title="请选择预约时间段"
                            extra="请选择预约时间段"
                            onChange={this.chooseSjd}
                            className="forss"
                            value={this.state.sjd}
                        >
                            <List.Item arrow="horizontal"><span className="error">*</span>预约明细时间</List.Item>
                        </Picker>
                        <InputItem value={this.state.ryxm} >
                            <div ><span className="error">*</span>姓名</div>
                        </InputItem>
                        {/* <InputItem value={this.state.sfzh} >
                            <div ><span className="error">*</span>身份证号</div>
                        </InputItem> */}
                        <InputItem value={this.state.phone} error={this.state.hasErrorPhone}
                        onErrorClick={this.onErrorClickPhone}
                        onChange={this.onChangePhone} >
                            <div ><span className="error">*</span>手机号</div>
                        </InputItem>
                    </List>
                    <div className="gs" style={{marginTop:'20px'}}>注意事项：</div>
                <div className="gs" >1、超市每人每天只能预约一次</div>
                    <div className="gs" >2、预约成功后需要在当天已预约时间段开始前二十分钟和开始后十分钟进行验证，过期则验证失效。（全天开放的场所忽略此规则）
                    </div>
                    <div className="formSubmit">
                        <button className="submit" type="submit">
                            提 交
                        </button>
                    </div>
                </form>

            </div>
        )
    }
}
