import React, { Component } from 'react'
import { List, Icon, NavBar } from 'antd-mobile';
import './index.css'
import { BASE_URL, Axios } from '../../../utils/url.js'
const Item = List.Item;
const Brief = Item.Brief;

export default class sysm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            content:''
        }
    }
    componentDidMount = () => {

         this.sysm()

    }
    async sysm() {
        const url = BASE_URL+'bclcrsqbController/sysm';
        var params = new URLSearchParams();
        await Axios({
            method: 'post',
            url: url,
            data: params
        }).then((res) => {
            if(res.status === 200){

                  this.setState({
                    content: res.data[0].content,
                }, () => {

                });
            }
        });
    }

    render() {
        return (
            <div>
                <NavBar
                    icon={<Icon type="left" />}
                    mode="dark"
                    onLeftClick={() => this.props.history.goBack()}

                >使用说明</NavBar>

                <div dangerouslySetInnerHTML={{ __html: this.state.content}} >



                </div>
            </div>
        )
    }
}