import React from 'react'
import { Route } from 'react-router-dom'
import { BASE_URL, Axios } from '../../utils/url'
import Yylb from '../yylb'
import Grzx from '../grzx'
// 导入 TabBar
import { TabBar } from 'antd-mobile'

/*
  1 根据 TabBar 组件 文档 设置不渲染内容部分（只保留菜单项，不显示内容）。
  2 给 TabBar.Item 绑定点击事件。
  3 在点击事件中调用 history.push() 实现路由切换。
  4 创建 TabBar 组件菜单项对应的其他 3 个组件，并在 Home 组件中配置路由信息。
  5 给菜单项添加 selected 属性，设置当前匹配的菜单项高亮。
*/

// 导入组件自己的样式文件
import './tantumain.css'

// TabBar 数据
const tabItems = [
  {
    title: '首页',
    icon: 'icon-ind',
    path: '/tantumain'
  },
  {
    title: '我的',
    icon: 'icon-my',
    path: '/main/grzx'
  }
]

export default class Main extends React.Component {
  constructor(props) {
    super(props);
    // const _params = window.location.href.split('?')[1].split("&")
    // console.log(_params);
    // const params1 = {}
    // for (let p of _params) {
    //   const [k, v] = p.split("=")
    //   params1[k] = v
    // }
    // var yhid = params1.yhid;
    var ttyhid = localStorage.getItem("yhid");
    var tag = localStorage.getItem("tag");
    // if (tag==="tantu") {
      var params = new URLSearchParams();
      params.append('yhid', ttyhid);
      Axios({
        method: 'post',
        url: BASE_URL + "bRyzcController/ttgetuser",
        data: params
      }).then((res) => {
        console.log(res);
        localStorage.setItem("userInfo", JSON.stringify(res.data.userInfo));
        localStorage.setItem("bshtoken", JSON.stringify(res.data.bshtoken));
      });
    // } else {
    //   if (_params.length > 0) {
    //     for (let p of _params) {
    //       const [k, v] = p.split("=")
    //       params1[k] = v
    //     }
    //     var openid1 = _params.openid;
    //     var params = new URLSearchParams();
    //     localStorage.setItem("openID", openid1);
    //     params.append('openid', openid1);
    //     Axios({
    //       method: 'post',
    //       url: BASE_URL + "bRyzcController/getUserInfo",
    //       data: params
    //     }).then((res) => {
    //       console.log(res);
    //       localStorage.setItem("userInfo", JSON.stringify(res.data.userInfo));
    //       localStorage.setItem("bshtoken", JSON.stringify(res.data.bshtoken));
    //     });
    //   }
    // }

    this.state = {
      // 默认选中的TabBar菜单项
      selectedTab: this.props.location.pathname
    }
  }


  // 渲染 TabBar.Item
  renderTabBarItem() {
    return tabItems.map(item => (
      <TabBar.Item
        title={item.title}
        key={item.title}
        icon={<i className={`iconfont ${item.icon}`} />}
        selectedIcon={<i className={`iconfont ${item.icon}`} />}
        selected={this.state.selectedTab === item.path}
        onPress={() => {
          this.setState({
            selectedTab: item.path
          })
          // 路由切换
          this.props.history.push(item.path)
        }}
      />
    ))
  }

  render() {
    console.log(this.props.location.pathname)
    return (
      <div className="home">
        {/* 2.3 渲染子路由 */}
        <Route exact path="/tantumain" component={Yylb} />
        <Route path="/main/grzx" component={Grzx} />
        {/* TabBar */}

        <TabBar tintColor="#108EE9" noRenderContent={true} barTintColor="white">
          {this.renderTabBarItem()}
        </TabBar>
      </div>
    )
  }
}
