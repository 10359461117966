import './jfqr.css'
import React, { Component } from 'react'
import { Grid,NavBar ,Icon} from 'antd-mobile';
import {BASE_URL,DOWNFILEURL } from  '../../utils/url'
import download from '../../assets/images/download.png'
import download2 from '../../assets/images/download2.png'
import download3 from '../../assets/images/wyfjf.png'
import download4 from '../../assets/images/yktbksq.png'
const xgbdxz = [
    { icon: download2, text: "担保证明" },
    { icon: download, text: "单位证明" },
    { icon: download3, text: "租赁合同" },
    { icon: download4, text: "治安责任保证书" }
];
export default class Czfbd extends Component {
    openDownload=e=>{
        if(e.text==="担保证明"){
            window.open( DOWNFILEURL+'yktsqxx/download?fjlx=dbzmfj')
        }else if(e.text==="单位证明"){
            window.open( DOWNFILEURL+'yktsqxx/download?fjlx=dwzmfj')
        }else if(e.text==="租赁合同"){
            window.open( DOWNFILEURL+'yktsqxx/download?fjlx=zzhtfj')
        }else if(e.text==="治安责任保证书"){
            window.open( DOWNFILEURL+'yktsqxx/download?fjlx=czwzazrbzsfj')
        }
    }
    render() { 
        return (
            <div style={{ marginTop: ".89rem"}}>
                <NavBar
              mode="dark"
              icon={<Icon type="left" />}
              
             onLeftClick={() => this.props.history.goBack()}
            >出租房表单下载</NavBar>
                <div style={{paddingBottom:"100px"}}>
                
                <div className="grid-css-child">相关表单下载 </div>
                <Grid data={xgbdxz} activeStyle={false} hasLine={false} onClick={this.openDownload}/>
                <p style={{color:"red",lineHeight:'30px',fontSize:'15px',margin:'10px 10px 10px 10px'}}>
            提示：<br />1、合同租赁期满或提前终止时，房主务必到客户服务项目部服务大厅5号窗口办理退租手续，办理退租手续时需持有当月物业费缴费清单。若不办理退租手续,该住房将继续按出租房收费标准收取相关费用。<br />2、租赁形式：（1）职工租房为油田本部员工租房（2）个人租房为外部个人租房（3）单位租房为外部单位租房 </p>
                
                </div>

                
            </div>
        )
    }
}

