import './clbg.css'
import React, { Component } from 'react'
import { BASE_URL, Axios } from  '../../../utils/url'
import {NavBar,Icon,List, Toast,ActionSheet,Modal} from 'antd-mobile'
const Item = List.Item;
const Brief = Item.Brief;
const isIPhone = new RegExp('\\biPhone\\b|\\biPod\\b', 'i').test(window.navigator.userAgent);
let wrapProps;
if (isIPhone) {
  wrapProps = {
    onTouchStart: e => e.preventDefault(),
  };
}
export default class Cljf extends Component {
    constructor(props){
        super(props);
localStorage.removeItem('clxx')
        this.state = {
            cllist: [],
            isLoaded: false,
            yhid:"",
            yhxm:"",

        }
    }
    componentDidMount = (e) => {
        if(e){
            Toast.info("删除成功！")
        }
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        this.setState({
            yhxm: userInfo.ryxm,
            yhid: userInfo.sfzh
        })
        console.log(userInfo.sfzh)
        this.getClglList(userInfo.sfzh);

    }

    //获取列表
    async getClglList(sfzh) {
        const url = BASE_URL+'bclcrsqbController/getClList';
        var params = new URLSearchParams();
        params.append("sfzh", sfzh);
        params.append("hhid", localStorage.getItem("bshtoken"));
        //获取人员身份证号并传递参数
        Toast.loading("加载中...",0,null,true);
        await Axios({
            method: 'post',
            url: url,
            data: params
        }).then((res) => {
            Toast.hide();
            if (res.status === 200) {
              console.log(res.data.length);
              var list =[];

              if(res.data){
                if(res.data.length >0){
                  for(var i=0;i<res.data.length;i++){
                    if(res.data[i].yxdqzt ==="已发放"){
                      list.push(res.data[i])

                    }
                  }
                }
                this.setState({
                  cllist: list,
                  isLoaded: true
              });
              if(list.length === 0){
                Toast.info("暂未查到需要变更的车辆")
              }
              }else{
                Toast.info("暂未查到需要变更的车辆")
              }


            }
        });
    }
    handleJump=(item)=>{
            this.showActionSheet(item)
    }
    showActionSheet = (item) => {
        console.log(item)
        const BUTTONS = ['权限变更', '查看详情', '取消'];
        ActionSheet.showActionSheetWithOptions({
          options: BUTTONS,
          cancelButtonIndex: BUTTONS.length - 1,
          destructiveButtonIndex: BUTTONS.length - 3,
          // title: 'title',
          message: '请选择您的操作',
          maskClosable: true,
          'data-seed': 'logId',
          wrapProps,
        },
        (buttonIndex) => {
            console.log(buttonIndex);
          if(buttonIndex ===0){
            this.props.history.push({ pathname : "./clbgEitder" , query : { clxx : item }});
          }else if(buttonIndex === 1){
            this.props.history.push({ pathname : "./clxxOrLc" , query : { clxx : item }});
          }
        });
      }
    setMapList = () => {
        if (!this.state.isLoaded) {
            return null;
        } else {
            return this.state.cllist.map((item, key) => (
                <Item multipleLine extra={item.crzlx} key={key} onClick={() => this.handleJump(item)}>
                    {item.cph} <Brief>{item.sqr}</Brief>
                    <Brief>{item.yxdqzt}</Brief>
                </Item>
            ))
        }
    }
    render() {
        return (
            <div style={{ marginTop: ".89rem" }}>
                {/* 导航栏#0F84D6 */}
                <NavBar
                    mode="dark"
                    icon={<Icon type="left" />}
                    onLeftClick={() => this.props.history.go(-1)}
                >车辆管理</NavBar>
                <div className="divmagin">
                    {this.setMapList()}
                </div>
            </div>
        )
    }
}
