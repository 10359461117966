import './openlittleapp.css'
import React, { Component } from 'react'
import { BASE_URL, Axios } from '../../utils/url'
// import $ from 'jquery'

export default class  OpenLittleApp extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    };
    componentDidMount = () => {
        var btn = document.getElementById('launch-btn');
        btn.addEventListener('launch', function (e) {
            console.log('success');
        });
        btn.addEventListener('error', function (e) {
            console.log('fail', e.detail);
        });

        var natBtn = document.getElementById('national-btn');
        natBtn.addEventListener('launch', function (e) {
            console.log('national-grid:','success');
        });
        natBtn.addEventListener('error', function (e) {
            console.log('fail', e.detail);
        });
        var ua = navigator.userAgent.toLowerCase()
        var isWXWork = ua.match(/wxwork/i) == 'wxwork'
        var isWeixin = !isWXWork && ua.match(/micromessenger/i) == 'micromessenger'
        if (isWeixin) {
            var params = new URLSearchParams();
            var tokenUrl = window.location.href.split("#")[0];//这里【url参数一定是去参的动态的当前页网址】
            params.append('tokenUrl', tokenUrl);
            // params.append('ticket', "LIKLckvwlJT9cWIhEQTwfE8p_Cxxov9MmMTpfjQ0I52P-yQ_APR_dFJB5Ch6io-FINEAWsDNPVnvGfxTJhR8Ow");
            var ticket ="";
            Axios({
                method: 'get',
                // url: "http://www.bzbsh.cn/bshwxgzh/admin/getTicket.html",
                url: "http://www.bzbsh.cn:18080/bshwxgzh/admin/getTicket.html",
                data: {}
            }).then((res) => {
                //console.log("获取Ticket:",res.data);
                //alert(res.data);
                ticket = res.data;
                if(ticket =="" || ticket==null){
                    ticket = "LIKLckvwlJT9cWIhEQTwfE8p_Cxxov9MmMTpfjQ0I52P-yQ_APR_dFJB5Ch6io-FINEAWsDNPVnvGfxTJhR8Ow";
                }
                params.append('ticket', ticket);
                Axios({
                    method: 'post',
                    url: BASE_URL +"bRyzcController/getSign",
                    // url: "http://127.0.0.1:10000/bRyzcController/getSign",
                    data: params
                }).then((res) => {
                    var timestamp = res.data.timestamp;
                    var nonceStr = res.data.nonceStr;
                    var signature = res.data.signature;
                    var appId = res.data.appId;
                    // alert("signature:"+signature+" appId:"+appId+"  nonceStr:"+nonceStr);
                    window.wx.config({
                        //debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印
                        appId: appId, // 必填，公众号的唯一标识
                        timestamp: timestamp, // 必填，生成签名的时间戳
                        nonceStr: nonceStr, // 必填，填任意非空字符串即可
                        signature: signature, // 必填，填任意非空字符串即可
                        jsApiList: ['showOptionMenu', 'chooseImage', 'previewImage'], // 必填，随意一个接口即可
                        openTagList: ['wx-open-launch-weapp'], // 填入打开小程序的开放标签名
                    });
                });
            });
            // window.wx.config({
            //     //debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印
            //     appId: 'wxdfb052bce7ab45f2', // 必填，公众号的唯一标识
            //     timestamp: 1692176432, // 必填，生成签名的时间戳
            //     nonceStr: 'cb1fcb78-0542-4fbb-90f6-45b244f0953e', // 必填，填任意非空字符串即可
            //     signature: '9aee670e4428ba1c177d2a52c87bde43d0484b27', // 必填，填任意非空字符串即可
            //     jsApiList: ['showOptionMenu', 'chooseImage', 'previewImage'], // 必填，随意一个接口即可
            //     openTagList: ['wx-open-launch-weapp'], // 填入打开小程序的开放标签名
            // });
        }
        else {
            alert("请在微信中打开");
            // Axios({
            //     method: 'get',
            //     url: 'http://8.140.128.117:18080/bshwxgzh/admin/getTicket.html',
            //     data: {}
            // }).then((res) => {
            //     console.log("获取Ticket:",res.data);
            // });
        }
        window.wx.ready(function () {
            // alert("加载成功!!!");
            // btn.click();
        });
        window.wx.error(function(res){
            alert("加载失败!!!");
            // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
        });
    }

    render(){
        return(
        <div style={{ marginTop: ".89rem" }}>
            <wx-open-launch-weapp id="launch-btn" username="gh_722cb819ae99">
                <script type="text/wxtag-template">
                    <button className="btn">享惠家</button>
                </script>
            </wx-open-launch-weapp>
            <wx-open-launch-weapp id="national-btn" username="gh_56b2c43416a4">
                <script type="text/wxtag-template">
                    <button className="btn">国家电网</button>
                </script>
            </wx-open-launch-weapp>
        </div>
        )
    }
}