import './login.css';
import React from 'react';
import { BASE_URL, Axios } from '../../utils/url'
import { utftohex } from '../../assets/js/hex'
import logo from '../../assets/images/logo.png';
import { List, InputItem, WhiteSpace, Button, Toast } from 'antd-mobile';
const sm2 = require('sm-crypto').sm2;
var pubkeyHex =
    "040CD64F13359245B489D67BCD71A209F3FC5A0393CC0CB43F65A7F8F5852ECF03825D9E0C3A5C8E06B765E07F32505EB567126D5F7C420ABA7DBAA2790B0B4176";

export default class Login extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            zh: '',
            mm: '',
            hasErrorPhone: false,
            zfdz: ""
        }
    }
    zc = () => {
        Toast.info('该功能暂时不可用');
    }
    //获取密码
    getMm = (value) => {
        this.setState({
            mm: value,
        });
    }
    //绑定手机号phone的onchange事件
    onChangePhone = (value) => {
        const str = value.replace(/\s*/g, "");
        console.log(str);
        if (value.replace(/\s/g, '').length != 11) {
            this.setState({
                hasErrorPhone: true,
            });
        } else {
            this.setState({
                hasErrorPhone: false,
            });
        }
        this.setState({
            zh: str,
        });

    }
    //手机输入错误提示
    onErrorClickPhone = () => {
        if (this.state.hasErrorPhone) {
            Toast.info('请输入正确的手机号');
        }
    }
    //提交
    denglu = () => {
        Toast.info('该功能暂时不可用');
    }
    wjmm=()=>{
        Toast.info('该功能暂时不可用');
    }
    render() {
        return (
            <div className="App">
                <img src={logo} className="logo"></img>
                <p className="app-name">宝石花移动服务大厅</p>
                <div className="login">
                    <InputItem
                        type="phone"
                        placeholder="请输入手机号"
                        value={this.state.zh}
                        error={this.state.hasErrorPhone}
                        onErrorClick={this.onErrorClickPhone}
                        onChange={this.onChangePhone}
                    >手机号码</InputItem>
                    <InputItem
                        type="password"
                        placeholder="请输入密码"
                        value={this.state.mm}
                        onChange={this.getMm}
                    >密码</InputItem>
                    <List style={{ margin: '5px 0', backgroundColor: 'white' }} >
                        <List.Item
                            extra={<Button type="ghost" size="small" inline onClick={this.zc}>注册</Button>}
                            multipleLine
                        >
                            没有账号？
      <List.Item.Brief>
                                点击右方按钮注册吧~
      </List.Item.Brief>
                        </List.Item>

                    </List>
                    <div style={{textAlign:"right",padding:"10px"}} onClick={this.wjmm}>忘记密码</div>
                    <div className="loginButton"><Button type="primary" onClick={this.denglu}>登录</Button><WhiteSpace /></div>

                </div>
                <div className="aclass">
                    <a href="https://beian.miit.gov.cn/" style={{ color: "#c1c1c1" }}>新ICP备2021000675号-1  新公网安备65280102000159号</a>
                </div>

            </div>
        );
    }
}

