import './cljf.css'
import React, { Component } from 'react'
import { BASE_URL, YL_URL, PAGE_URL, Axios } from '../../../utils/url'
import Wxzf from '../../../assets/images/wxzf.png'
import Zfbzf from '../../../assets/images/zfbzf.png'
import { NavBar, Icon, List, Toast, Modal, InputItem, Button, Radio } from 'antd-mobile';
const RadioItem = Radio.RadioItem;
const Item = List.Item;
const Brief = Item.Brief;
export default class Cljf extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dsplist: [],
            isLoaded: false,
            modal: false,
            value: "",
            merOrderId: "",
            isPay: false,
            zfdz: "",
            id: '',
            item: ""
        }
    }
    componentDidMount = () => {
        if (this.isWeiXin()) {
            this.setState({
                value:"WXPay.jsPay"
            });
        } else {
            this.setState({
                value:"trade.precreate"
            });
        }
        // this.getList();
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        if (userInfo === null) {
            const openid = this.props.location.search;
            if (openid.length > 0) {
                var open = openid.split("&");
                var openidArr = open[0].split("=");
                var openid1 = openidArr[1];
                var params = new URLSearchParams();
                localStorage.setItem("openID", openid1);
                params.append('openid', openid1);
                Axios({
                    method: 'post',
                    url: BASE_URL+"/bRyzcController/getUserInfo",
                    data: params
                }).then((res) => {
                    localStorage.setItem("userInfo", JSON.stringify(res.data.userInfo));
                    localStorage.setItem("bshtoken", JSON.stringify(res.data.bshtoken));
                    const url = BASE_URL + 'bjfjlController/getJfList';
                    var params = new URLSearchParams();
                    params.append('sfzh', userInfo.sfzh);
                    Toast.loading("加载中...", 0, null, true);
                    Axios({
                        method: 'post',
                        url: url,
                        data: params
                    }).then((res) => {
                        Toast.hide();
                        console.log(res);
                        if (res.status === 200) {
                            this.setState({
                                dsplist: res.data,
                                isLoaded: true
                            });

                        }
                    });
                });

            }
        } else {
            const url = BASE_URL + 'bjfjlController/getJfList';
            var params = new URLSearchParams();
            params.append('sfzh', userInfo.sfzh);
            Toast.loading("加载中...", 0, null, true);
            Axios({
                method: 'post',
                url: url,
                data: params
            }).then((res) => {
                Toast.hide();
                console.log(res);
                if (res.status === 200) {
                    this.setState({
                        dsplist: res.data,
                        isLoaded: true
                    });

                }
            });
        }


    }
    onClose = key => () => {
        this.setState({
            [key]: false,
        });
    }
    handleDjf(item) {
        if (item.je === 0) {
            // String jfid,String zfzt,String zfsj,String jffs,String cph,String sl,
            // String je,String dj,String jldw,String jfxmmc,String jfxmid
            const url = BASE_URL + 'bjfjlController/save';
            var params = new URLSearchParams();
            //获取车辆缴费信息，传递参数车牌号
            params.append("jfid", item.jfid);
            params.append("zfzt", "已缴费");
            params.append("jffs", "现金支付");
            params.append("cph", item.cph);
            params.append("sl", item.sl);
            params.append("je", item.jee);
            params.append("dj", item.dj);
            params.append("jldw", item.jldw);
            params.append("jfxmmc", item.jfxmmc);
            params.append("jfxmid", item.jfxmid);
            params.append("jfje", item.jee);

            Toast.loading("加载中...", 0, null, true);
            Axios({
                method: 'post',
                url: url,
                data: params
            }).then((res) => {
                Toast.hide();
                if (res.status == 200) {
                    Toast.info("缴费成功", 1, null, false);
                    this.props.history.go(-1)
                }
            });
        } else {
            this.setState({
                modal: true,
                item: item
            });
        }
    }
    setMapList = () => {
        //
        if (!this.state.isLoaded) {
            return null;
        } else {
            if(this.state.dsplist.length>0){
                return this.state.dsplist.map((item, key) => (
                    <Item multipleLine extra={item.je + "元"} key={key} onClick={() => this.handleDjf(item)}>
                        {item.cph} <Brief>{item.cz}</Brief>
                        <Brief>{item.dqzt}</Brief>
                    </Item>
                ))
            }else{
                return <div style={{fontSize:"14px",textAlign:"center",color:"rgb(243 75 98)",border:"1px solid white",marginTop:"50px"}}>*您当前没有待缴费的车辆，请先提交车辆延期申请</div>
            }

        }
    }
    // 获取选择车库信息
    onChange = (value, label) => {
        this.setState({
            value,
        });
    };
    //判断是否是微信浏览器的函数
    isWeiXin() {
        //window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
        var ua = window.navigator.userAgent.toLowerCase();
        //通过正则表达式匹配ua中是否含有MicroMessenger字符串
        if (ua.match(/MicroMessenger/i) == 'micromessenger') {
            return true;
        } else {
            return false;
        }
    }
    setOrder = () => {
        if (this.state.value === '') {
            Toast.info("请选择支付类型", 1, null, false);
        } else {
            //const url = YL_URL + 'xiadanController/order';
            if (this.isWeiXin() && this.state.value === "trade.precreate") {
                let openId = localStorage.getItem("openID");
                //alert(openId);
                window.parent.frames.location.href = PAGE_URL+"?openId=" + openId+"&page=cljf";
                return;
            }
            const userInfo = JSON.parse(localStorage.getItem("userInfo"));
            var url = "";

            if (this.isWeiXin()) {
                url = YL_URL + 'xiadanWXController/order'
            } else {
                url = YL_URL + 'xiadanController/order';
            }
            var params = new URLSearchParams();
            params.append('totalAmount', this.state.item.je);
            params.append('goodsId', this.state.item.jfid);
            //params.append('goodsName', this.state.item.bz);
            params.append('goodsName', "车辆缴费");
            params.append('id', this.state.item.cph);
            params.append('price', this.state.item.je);
            params.append('payMethod', this.state.value);
            params.append('yhid', userInfo.sfzh);
            params.append('yhxm', userInfo.ryxm);
            params.append('ywbm', "CLJF");
            // params.append("openid", localStorage.getItem("openId"));
            Toast.loading("加载中...", 0, null, true);
            Axios({
                method: 'post',
                url: url,
                data: params
            }).then((res) => {
                Toast.hide();
                if (this.state.value == "WXPay.jsPay") {
                    if (res.status == '200') {
                        window.parent.frames.location.href = res.data.data;
                    } else {
                        alert("支付失败，请重新支付");
                    }
                } else {
                    if (res.status == '200') {
                        let jsonData = JSON.parse(res.data.data);
                        if (jsonData.errCode == 'SUCCESS' && jsonData.xdmsg == "下单成功!") {
                            let payJson = JSON.parse(jsonData.respStr);
                            this.setState((state, props) => {
                                return {
                                    zfdz: payJson.appPayRequest.qrCode,
                                    merOrderId: payJson.merOrderId
                                }
                            },
                                () => {
                                    //支付
                                    Modal.alert('确认支付？', '确认支付？', [
                                        { text: '取消', onPress: () => console.log('下次一定') },
                                        {
                                            text: '确定',
                                            onPress: () =>
                                                new Promise((resolve) => {
                                                    this.setState({
                                                        isPay: true
                                                    });
                                                    //跳转到登录
                                                    window.open(this.state.zfdz);
                                                    this.props.history.push({ pathname: '/jfqr', query: { merOrderId: this.state.merOrderId } });
                                                    setTimeout(resolve, 500);
                                                }),
                                        },
                                    ])
                                }
                            )

                        } else {
                            alert("支付失败，请重新支付");
                        }
                    }
                }


            });
        }

    }


    render() {
        const { value } = this.state;
        var data = [
            //{ value: "WXPay.jsPay", label: '微信支付', img: Wxzf },
            //{ value: "trade.precreate", label: '支付宝支付', img: Zfbzf },
        ];
        if (this.isWeiXin()) {
            var obj = {};
            obj.value = "WXPay.jsPay";
            obj.label = "微信支付";
            obj.img = Wxzf;
            data.push(obj);
            var obj1 = {};
            obj1.value = "trade.precreate";
            obj1.label = "支付宝支付";
            obj1.img = Zfbzf;
            data.push(obj1);
        } else {
            var obj = {};
            obj.value = "trade.precreate";
            obj.label = "支付宝支付";
            obj.img = Zfbzf;
            data.push(obj);
        }
        return (
            <div style={{ marginTop: ".89rem" }}>
                {/* 导航栏#0F84D6 */}
                <NavBar
                    mode="dark"
                    icon={<Icon type="left" />}
                    onLeftClick={() => this.props.history.push({
                        pathname: './main',
                        search: `?isNone='none'`
                    })}
                >车辆缴费</NavBar>

                <div className="divmagin" >


                        {this.setMapList()}


                </div>
                {/* 选择排号车库 */}
                <Modal
                    popup
                    visible={this.state.modal}
                    onClose={this.onClose('modal')}
                    animationType="slide-up"
                    afterClose={() => { console.log('afterClose'); }}
                >
                    <List renderHeader={() => '选择支付方式'}>
                        {data.map(i => (
                            <RadioItem key={i.value} checked={value === i.value} onChange={() => this.onChange(i.value, i.label)}>
                                <img src={i.img} />  {i.label}
                            </RadioItem>
                        ))}
                        <Button type="primary" onClick={this.setOrder}>确认</Button>
                    </List>
                </Modal>
            </div>
        )
    }
}
