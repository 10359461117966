import './zc.css'
import React from 'react';
import { BASE_URL ,YL_URL, Axios} from  '../../utils/url'
import { utftohex,hextoutf } from '../../assets/js/hex'
import {NavBar, Modal, InputItem, WhiteSpace, Button, Toast,Icon,List,NoticeBar } from 'antd-mobile';

const sm2 = require('sm-crypto').sm2;
	const cipherMode = 1;
	var pubkeyHex =
		"040CD64F13359245B489D67BCD71A209F3FC5A0393CC0CB43F65A7F8F5852ECF03825D9E0C3A5C8E06B765E07F32505EB567126D5F7C420ABA7DBAA2790B0B4176";
	var priKey = "008ABF7E03C6BB305E36281DF816E5FB8F1076C61097040A152D9773D43899E292";
class zhuce extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      idcard: '',
      phone: '',
      password: '',
      rePass: '',
      hasError: false,
      hasErrorPhone: false,
      hasErrorPass: false,
      hasErroerePass: false,
      yzmStatus:false,
      time:120000,
      msg:"",
      yzmVal:''
    }
  }
  //身份证号错误提示
  onErrorClick = () => {
    if (this.state.hasError) {
      Toast.info('请输入正确的身份证号',1,null,false);
    }
  }
  //手机输入错误提示
  onErrorClickPhone = () => {
    if (this.state.hasErrorPhone) {
      Toast.info('请输入正确的手机号',1,null,false);
    }
  }
  //密码长度低于6位提示
  onErrorClickPass = () => {
    if (this.state.hasErrorPass) {
      Toast.info('密码长度不能低于6位！',1,null,false)
    }
  }
  //确认密码跟密码不一致提示
  onErrorClickrePass = () => {
    if (this.state.hasErrorrePass) {
      Toast.info('确认密码跟密码不一致！',1,null,false)
    }
  }
  //绑定name的onchange事件
  onChange = (value) => {
    this.setState({
      name: value,
    });

  }
  //绑定idcard的onchange事件
  onChangeId = (value) => {
    if (value.replace(/\s/g, '').length !== 18) {
      this.setState({
        hasError: true,
      });
    } else {
      this.setState({
        hasError: false,
      });
    }
    this.setState({
      idcard: value,
    });
  }
  //绑定手机号phone的onchange事件
  onChangePhone = (value) => {
    const str = value.replace(/\s*/g,"");
    console.log(str);
    if (value.replace(/\s/g, '').length !== 11) {
      this.setState({
        hasErrorPhone: true,
      });
    } else {
      this.setState({
        hasErrorPhone: false,
      });
    }

    this.setState((state, props) => {
      return {

        phone: str,
      }
    },
      () => {


      })

  }
  checkSjh = (e) => {
    const url = BASE_URL + 'bRyzcController/checkSjh';
    var params = new URLSearchParams();
    params.append('sjh', e);
    Axios({
      method: 'post',
      url: url,
      data: params,

    }).then((res) => {
      console.log(res)
      if (res.data.flag === false) {
        // Toast.info(res.data.msg,1,null,false)
        Toast.info(res.data.msg)
        this.setState({
          phone: ''
        })
      }

    });
    this.setState({
      phone: e
    })
  }
  onyzm =(value)=>{
    this.setState({
      yzmVal: value,
    });
  }
  hqyzm=()=>{
    console.log(this.state.phone.length)
    if(this.state.phone === ""){
      Toast.info("请输入手机号后获取验证码")
    }else if(this.state.phone.length !=11){
      Toast.info("请输入正确的手机号")
    }else{
      this.setState({
        yzmStatus:true
      })
      let maxtime = this.state.time / 1000; //按秒计算
            let timer = null;
            let _this = this;
            console.log(this.state.time)
            setTimeout(function f() {

                    if (maxtime > 0) {
                        let minutes = Math.floor(maxtime / 60);
                        let seconds = Math.floor(maxtime % 60);
                        minutes < 10 ? minutes = '0' + minutes : minutes = minutes;
                        seconds < 10 ? seconds = '0' + seconds : seconds = seconds;
                        let msg = minutes +"分"+seconds + "秒后重发";
                        _this.setState({
                            msg
                        });
                        --maxtime;
                    } else {

                      let msg = "获取验证码";
                      _this.setState({
                        yzmStatus:false,
                        msg
                      });
                        clearTimeout(timer);
                        return;
                    }



                timer = setTimeout(f, 1000);
            }, 1000);

              _this.getYzm();


    }
  }
  getYzm=()=>{
    console.log(this.state.yzmStatus)
    console.log(this.state.phone)
    const url = YL_URL+'duanXinController/sendMsg';
    var params = new URLSearchParams();
    params.append('sjh', this.state.phone);
    // Toast.loading("加载中...",0,null,true);
    Axios({
        method: 'post',
        url: url,
        data: params
    }).then((res) => {
        // Toast.hide();
        console.log(res)
        if(res.data.flag){
            // alert(res.data.flag);
            this.setState((state, props) => {
              return {

                yzm: res.data.yzm,
              }
            },
              () => {


              })
        }
    });
  }
  //输入完密码的事件
  onBlurPass = (value) => {

    if (value.replace(/\s/g, '').length < 6) {
      this.setState({
        hasErrorPass: true,
      });
    } else {
      this.setState({
        hasErrorPass: false,
      });
    }
    this.setState({
      password: value,
    });
    if (value !== this.state.rePass) {
      this.setState({
        hasErrorrePass: true,
      });

    } else {
      this.setState({
        hasErrorrePass: false,
      });

    }
  }
  //输入完确认密码的事件
  onBlurrePass = (value) => {
    if (value !== this.state.password) {
      this.setState({
        hasErrorrePass: true,
      });

    } else {
      this.setState({
        hasErrorrePass: false,
      });

    }
    this.setState({
      rePass: value,
    });

  }
  //提交
  qrzc = () => {
    if(this.state.yzm !== this.state.yzmVal){
      Toast.info("验证码输入错误");
      this.setState({
        yzm:""
      })
      return;
    }
    if (this.state.hasError || this.state.hasErrorPhone || this.state.hasErrorPass || this.state.hasErroerePass) {
      Toast.info('请先完善注册信息再提交~',1,null,false)
    } else {
      if (this.state.name === '' || this.state.idcard === '' || this.state.phone === '' || this.state.password === '' || this.state.rePass === '') {
        Toast.info('请先完善注册信息再提交~',1,null,false)
      } else {
        const url = BASE_URL+'bRyzcController/zc';
        var params = new URLSearchParams();
        params.append('xm', this.state.name);       //你要传给后台的参数值 key/value
        params.append('sfzh', this.state.idcard);
        params.append('sjh', this.state.phone);
        let encryptData = sm2.doEncrypt(utftohex(this.state.password), pubkeyHex, 0); // 加密结果
        // console.log(encryptData);
        params.append('mm', encryptData);
        Toast.loading("注册中...",0,null,true);
        Axios({
          method: 'post',
          url: url,
          data: params
        }).then((res) => {
          Toast.hide();
          console.log(res)
          if (res.data.iszc) {
            Modal.alert('注册成功', '是否去登录', [
              { text: '取消', onPress: () => console.log('取消') },

              {
                text: '确定',
                onPress: () =>
                  new Promise((resolve) => {
                    //跳转到登录
                    this.props.history.go(-1);
                    // Toast.info('onPress Promise', 1);
                    setTimeout(resolve, 1000);
                  }),
              },
            ])
          }else{
            Toast.info(res.data.msg,1,null,false);
          }
        });
      }
    }
  }
  render() {

    return (
      <div style={{ marginTop: ".89rem" }}>
        <NavBar
         icon={<Icon type="left" />}
         onLeftClick={() => this.props.history.go(-1)}
          mode="dark"
        >注册</NavBar>

         <List renderHeader={() => ''} className="my-list">
         <div style={{marginTop:-15}}>
        <NoticeBar mode="closable" icon={<Icon type="check-circle-o" size="xxs" />}>
         提示：必须实名注册，否则无法使用。
                        </NoticeBar>
        </div>
        <InputItem type="text" placeholder="请输入真实姓名" value={this.state.name} onChange={this.onChange}>真实姓名</InputItem>
        <InputItem
          maxLength="18"
          type="text"
          onChange={this.onChangeId}
          value={this.state.idcard}
          error={this.state.hasError}
          onErrorClick={this.onErrorClick}
          placeholder="请输入身份证号"
        >身份证号</InputItem>
        <InputItem
          type="phone"
          placeholder="186 1234 1234"
          error={this.state.hasErrorPhone}
          onErrorClick={this.onErrorClickPhone}
          onChange={this.onChangePhone}
          value={this.state.phone}
          // placeholder="请输入手机号"
          onBlur={this.checkSjh}
        >手机号码</InputItem>
        <InputItem
          type="text"
          placeholder="请输入验证码"
          onChange={this.onyzm}
          value={this.state.yzmVal}
          extra={<Button type="ghost" size="small" inline onClick={this.hqyzm}>{this.state.yzmStatus? `${this.state.msg}` :'获取验证码'}</Button>}
        >验证码</InputItem>
        <InputItem
          type="password"
          placeholder="****"
          value={this.state.password}
          onChange={this.onBlurPass}
          error={this.state.hasErrorPass}
          onErrorClick={this.onErrorClickPass}
          // placeholder="请输入密码"
        >密码</InputItem>
        <InputItem
          type="password"
          placeholder="****"
          value={this.state.rePass}
          onChange={this.onBlurrePass}
          error={this.state.hasErrorrePass}
          onErrorClick={this.onErrorClickrePass}
          // placeholder="请输入确认密码"
        >确认密码</InputItem>


        <div className="loginButton"><Button type="primary" onClick={this.qrzc}>确认注册</Button><WhiteSpace /></div>
        </List>


      </div>
    );
  }

}

export default zhuce;
