import './yktsq.css'
import React, { Component } from 'react'
import { NavBar, Icon, List,Card, WingBlank, WhiteSpace,Toast } from 'antd-mobile';
import { WY_URL, Axios } from '../../../utils/url';
import ygk from '../../../assets/images/ygk.png'
const Item = List.Item;
const Brief = Item.Brief;
export default class Yktsq extends Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            isLoaded: false,

        }
    }

    componentDidMount = () => {
        this.getList();
    }

    async getList() {
        console.log("执行获取数据");
        let ryobject = JSON.parse(localStorage.getItem('userInfo'));
        // console.log(ryobject.sfzh);
        let sfzh = "370630195209223043";
        const url = WY_URL + 'yktsqxx/getYktglList';
        var params = new URLSearchParams();
        // params.append("yhsfzh", ryobject.sfzh);
        params.append("yhsfzh", sfzh);
        await Axios({
            method: 'post',
            url: url,
            data: params
        }).then((res) => {
            console.log(res.data);
            if (res.status === 200) {
                this.setState({
                    list: res.data.records,
                    isLoaded: true
                });
            }
        });
    }

    async getjudge() {
        console.log("判断是否能申请");
        let ryobject = JSON.parse(localStorage.getItem('userInfo'));
        const url = WY_URL + 'yktsqxx/checkYhykt';
        var params = new URLSearchParams();
        let sfzh = "370630195209223043";
        // params.append("yhsfzh", ryobject.sfzh);
        params.append("yhsfzh", sfzh);
        await Axios({
            method: 'post',
            url: url,
            data: params
        }).then((res) => {
            console.log(res.data.data);
            if (res.status === 200) {
                if(res.data.data.check){
                    this.props.history.push('./yktsqedit');
                }else{
                    Toast.info(res.data.data.msg, 4, null, false);
                }
            }
        });
    }

    setMapList = () => {
        if (!this.state.isLoaded) {
            return null;
        } else {
            return this.state.list.map((item, key) => (
                <WingBlank size="lg" key={key}>
                <WhiteSpace size="lg" />
                <Card onClick={() => this.handleJump(item)}>
                    <Card.Header
                        title={item.yhxm}
                        thumb={ygk}
                        extra={<span>{'申请类型：' + item.yktlx}</span>}
                    />
                    <Card.Body>
                        <div>{item.zhlxmc}</div>
                    </Card.Body>
                    <Card.Footer content={'申请时间：' + item.firsttime.slice(0,10) } extra={<div>{'当前状态：' + item.dqzt}</div>}  />
                </Card>
                <WhiteSpace size="lg" />
            </WingBlank>
            ))
        }
    }

    handleSq = () => {
        this.props.history.push('./yktsqedit');
        // this.getjudge();
    }

    handleJump = (e) => {
        this.props.history.push({ pathname : "./yktsqshow" , query : {yktglid:e.yktglid}});
    }

    render() {
        return (
            <div style={{ marginTop: ".89rem" }}>
                {/* 导航栏#0F84D6 */}
                <NavBar
                    mode="dark"
                    icon={<Icon type="left" />}
                    rightContent={[
                        <div onClick={this.handleSq}>申请</div>
                    ]}
                    onLeftClick={() => this.props.history.goBack()}
                >一卡通申请</NavBar>
                <div className="divmagin">
                    {this.setMapList()}
                </div>
            </div>
        )
    }
}
