import React, { Component } from 'react'
import { NEW_YGK_URL, Axios } from '../../../utils/url'
import lrz from "lrz";
import { SFZ_URL } from '../../../utils/fjyl';
import { SearchBar, DatePicker, Picker, Tabs, NavBar, InputItem, List, TextareaItem, Radio, Button, Toast, ImagePicker, Icon, Modal, PickerView, Card } from 'antd-mobile';
// import { ImagePicker, Image } from 'antd-mobile';
import './yysq.css'
const nowTimeStamp = Date.now();
const RadioItem = Radio.RadioItem;

// Make sure that in `time` mode, the maxDate and minDate are within one day.
const minDate = new Date(nowTimeStamp);
const tabs = [
    { title: "预约基本信息" },
    // { title: '人员信息' }
];
const Item = List.Item;
const Brief = Item.Brief;
export default class YysqUpdate extends Component {
    constructor(props) {
        super(props);
        let isBgBool = false;

        var type = this.props.location.query.type
        let isViewedit = true;
        this.listSize = 0;



        this.state = {
            isInFirstTab: true,
            crdValue: "",
            showInput: false,
            showmsg: '请输入单位名称',
            editviewable: isViewedit,
            infotype: type,
            openOrgUser: true,
            errormsg: '',
            deptqueyDwmx: [],//单位明细
            maxcols: '',
            orgcode: '',
            orgname: "",
            yyrq: '',
            tempOrg: '',
            tempUser: '',
            dwsc: false,
            grsc: false,
            orgUser: false,
            dwlist: [],
            userlist: [],
            headData: {
                note: "",
                licenseplate: "",
                fDjlx: '0003',
                reservationId: "",
                lntervieweeCall: "",//联系电话
                lntervieweeType: "",//*拜访对象类型：
                lntervieweeName: "",//联系人
                postionName: "",//出入名称
                fZzjg: '01',  //组织机构
                reservationTime: "",     //出入申请时间
                visitType: "",//来访类型
                subjectMatter: "",//拜访事由
                positionCode: "",//出入地方
                lntervieweeAddress: "",//拜访地址
                createUserId: "",//申请人
                createUserName: "",//姓名
                reservationState: '待提交',
            },
            ifSave: false,
            InputBfdz: false,
            InputBfmc: false,
            value1: null,
            value2 : null,
            imgFlag : false,
            fileUrl: "",
            files: [],
            multiple: false,
            yl:false,
            dataFormList: [],
            ryxxlist: [],
            isLoaded: false,
            merOrderId: "",
            isBg: isBgBool,
            value: "",
            bglx: "",
            showxs: false,
            showqsbg: false,
            xssjh: "",
            locationzd: "",
            lfdxlx:"",
            lfcode: 0,
            bfcode: 0,
            crcode: [0],
            sexcode: [0],
            ofdjlx: [{ value: '0', label: '个人' }, { value: '1', label: '单位' }],
            lfdjlx: [{ value: '0', label: '个人' }, { value: '1', label: '车辆' }],
            xb: [{ value: '0', label: '男' }, { value: '1', label: '女' }],
            flag: "",
            yhxx: {},
            sizeMX: 0,
            returnAddRowFunList: [

                // {
                //     id: "1"
                // },
            ]
        }

    }


    // handleItemDelete(index) {
    //     // 拷贝一份，不要直接去修改state里面的数据
    //     const list = [...this.state.returnAddRowFunList];
    //     list.splice(index - 1, 1)
    //     this.state.returnAddRowFunList = list
    //     this.setState({
    //         returnAddRowFunList: list
    //     })
    //     this.returnAddRowFun()
    //     this.state.sizeMX =
    //         this.setState({
    //             sizeMX: list.length + 1
    //         })
    //     this.forceUpdate()
    // }
    handleTabChange = (tab) => {
        if (tab === 0) {
          // 切换到第一个页面
          this.setState({ isInFirstTab: true });
        } else {
          // 切换到其他页面
          this.setState({ isInFirstTab: false });
        }
      };

    handleItemDelete = (index) => {
        const list = [...this.state.returnAddRowFunList];
        if (list.length === 1) {
            return; // 如果只剩下一个项目，不执行删除操作
          }

        list.splice(index, 1);
        // const newList = list.map((obj, index) => ({
        //     ...obj,
        //     id: `${index + 1}`,
        //   }));
        this.setState({
          returnAddRowFunList: list,
          sizeMX: list.length + 1
        }, () => {
            this.returnAddRowFun();
            this.forceUpdate();
        });
      }



    returnAddRowFun = () => {

        let { returnAddRowFunList, cacheTypeList } = this.state;
        return (

            returnAddRowFunList && returnAddRowFunList.length && returnAddRowFunList.map((item,index) => {

                // this.state[`visitorphotourl${item.id}`] =[];

                return (


                    <React.Fragment key={item.id}>

                        {
                            (

                                <div className="card" >
                                    <List renderHeader={() => `人员信息`} >
                                    {
                                        <div className="btnMx">


                                                {index === returnAddRowFunList.length - 1 && (
                                                <button className="addBtn" onClick={this.addRowFunBACK}
                                                >增加</button>)}
                                                <span ></span>

                                                <button className="delBtn" onClick={() => this.handleItemDelete(index)}>删除</button>


                                        </div>
                                    }
                                        <InputItem
                                            type="text" placeholder="请输入访客姓名"
                                            value={this.state[`visitorName${item.id}`]}
                                            editable={this.state.editviewable}
                                            ref={el => this.labelFocusInst = el}
                                            onChange={(e) => this.changngemxdata(e, item.id, 'visitorName')}
                                        ><div>
                                                <font color="red">*</font>访客姓名：</div> </InputItem>


                                        <Picker
                                            data={this.state.xb}
                                            cols={1}
                                            onChange={(e) => this.changngxbdata(e, item.id)}
                                            editable={this.state.editviewable}
                                            className="forss"
                                            value={this.state[`sexdata${item.id}`]}
                                            extra="请选择访客性别"
                                        >
                                            <List.Item arrow="horizontal"><span className="error">*</span>访客性别</List.Item>
                                        </Picker>

                                        <InputItem
                                            type="text" placeholder="请输入访客地址"
                                            value={this.state[`visitorAddress${item.id}`]}
                                            editable={this.state.editviewable}
                                            ref={el => this.labelFocusInst = el}
                                            onChange={(e) => this.changngemxdata(e, item.id, 'visitorAddress')}
                                        ><div>
                                                <font color="red">*</font>访客地址：</div>
                                        </InputItem>
                                        { }
                                        <InputItem
                                            type="text" placeholder="访客联系方式"
                                            value={this.state[`visitorCall${item.id}`]}
                                            editable={this.state.editviewable}
                                            ref={el => this.labelFocusInst = el}
                                            onChange={(e) => this.changngemxdata(e, item.id, 'visitorCall')}
                                        ><div>
                                                <font color="red">*</font>访客联系方式：</div> </InputItem>
                                        <InputItem
                                            type="text" placeholder="请输入证件号码"
                                            value={this.state[`identificationNumber${item.id}`]}
                                            editable={this.state.editviewable}
                                            ref={el => this.labelFocusInst = el}
                                            onChange={(e) => this.changngemxdata(e, item.id, 'identificationNumber')}
                                        ><div>
                                                <font color="red">*</font>证件号码：</div> </InputItem>
                                        <TextareaItem
                                            title="备注："
                                            data-seed="logId" placeholder="请输入"
                                            editable={this.state.editviewable}
                                            ref={el => this.autoFocusInst = el}
                                            value={this.state[`note${item.id}`]}
                                            onChange={(e) => this.changngemxdata(e, item.id, 'note')}
                                            autoHeight
                                        />


                                        {/* <InputItem
                                        value={this.state[`passWord${item.id}`]}
                                        title={`文件名称${item.id}`}
                                        placeholder="请输入"
                                        onChange={(e) => this.inputName(`passWord${item.id}`, e.target.value)} /> */}
                                    </List>
                                    <Modal
                                        visible={ this.state.yl }
                                        transparent
                                        footer={[{ text: '关闭', onPress: () => {this.setState({ yl: false })}} ]}
                                        >
                                        <div style={{ marginTop:50, transform:this.state.transStyle, display:'flex', alignItems:'center'}}>
                                            <img
                                                alt="example"
                                                style={{width: '100%',height:'100%' }}
                                                src={SFZ_URL}
                                            />
                                        </div>
                                    </Modal>
                                    <div className="scfj" style={{
                                        fontSize: "14px",
                                        color: "#888"
                                        }}>上传附件</div>
                                    <div>
                                        <div style={{ paddingTop: "10px" }}>
                                        </div>
                                        <div className="scfj" style={{display: "flex", alignItems: "flex-start"}}>
                                            <span style={{ color: "red"}}>*</span>
                                            上传身份证正反面(
                                                <span onClick={() => this.setState({ yl: true })} style={{
                                                color: "#108ee9",}}>查看样例</span>)
                                        </div>



                                        <ImagePicker
                                            files={this.state[`visitorphotourl${item.id}`]}
                                            // files={this.state.files}
                                            onChange={(files, type, index) => this.onChangeFile(files, type, index, item.id, `visitorphotourl`)}
                                            onImageClick={(index, fs) => console.log(index, fs)}
                                            selectable={this.state[`visitorphotourl${item.id}`]?this.state[`visitorphotourl${item.id}`].length < 2:true}
                                            multiple={this.state.multiple}
                                            capture="camera"
                                        />
                                        {/* {imagePath && <Image src={this.state.fileUrl} alt="展示图片" />} */}
                                    </div>
                                </div>
                            )
                        }

                        {


                        }

                    </React.Fragment>
                )
            })
        )
    }



    // addRowFunBACK = () => {
    //     let { returnAddRowFunList } = this.state;
    //     let addId = returnAddRowFunList.length + 1
    //     let obj = {}

    //     obj[`id`] = `${addId}`

    //     let stateObj = {}
    //     stateObj[`createUserId${addId}`] = ""
    //     stateObj[`identificationNumber${addId}`] = ""
    //     stateObj[`note${addId}`] = ""
    //     stateObj[`visitorAddress${addId}`] = ""
    //     stateObj[`visitorCall${addId}`] = ""
    //     stateObj[`visitorName${addId}`] = ""
    //     stateObj[`visitorSex${addId}`] = ""
    //     this.state.sizeMX = addId
    //     this.setState({
    //         sizeMX: addId,
    //     })

    //     this.setState(stateObj)
    //     this.setState({
    //         returnAddRowFunList: [...returnAddRowFunList, obj],
    //     })

    //     this.forceUpdate()
    // }
    addRowFunBACK = () => {
        const { returnAddRowFunList } = this.state;
        let addId = returnAddRowFunList.length > 0 ? returnAddRowFunList[returnAddRowFunList.length - 1].id : 0;
        addId = +addId+1
        // const addId = returnAddRowFunList.length + 1;
        const obj = {
          id: `${addId}`
        };

        const stateObj = {};
        stateObj[`createUserId${addId}`] = "";
        stateObj[`identificationNumber${addId}`] = "";
        stateObj[`note${addId}`] = "";
        stateObj[`visitorAddress${addId}`] = "";
        stateObj[`visitorCall${addId}`] = "";
        stateObj[`visitorName${addId}`] = "";
        stateObj[`visitorSex${addId}`] = "";
        stateObj[`visitorphotourl${addId}`]= [];

        this.setState({
          sizeMX: addId,
          ...stateObj,
          returnAddRowFunList: [...returnAddRowFunList, obj],
        }, () => {
          this.forceUpdate();
        });
        this.listSize = returnAddRowFunList.length + 1 ;
      };

    checkData = (varl) => {
        this.state.errormsg = ''
        this.setState({

            errormsg: ''
        })

        let checkresult = true
        let { returnAddRowFunList } = this.state;
        let arrObj = []
        returnAddRowFunList.map(item => {
            let obj = {}
            obj[`visitorSex`] = this.state[`visitorSex${item.id}`]
            obj[`visitorCall`] = this.state[`visitorCall${item.id}`]
            obj[`visitorName`] = this.state[`visitorName${item.id}`]
            obj[`note`] = this.state[`note${item.id}`]
            obj[`visitorAddress`] = this.state[`visitorAddress${item.id}`]
            obj[`createUserId`] = this.state[`createUserId${item.id}`]
            obj[`identificationNumber`] = this.state[`identificationNumber${item.id}`]
            obj[`visitorphotourl`] = this.state[`visitorphotourl${item.id}`]

            arrObj = [...arrObj, obj]
        })
        var headobj = {

            visitType: "来访对象类型",
            positionCode: "出入地方",//出入地方
            lntervieweeType: "拜访对象类型",//*拜访对象类型：
            lntervieweeName: "拜访对象名称",//*拜访对象：
            lntervieweeCall: "拜访对象联系电话",
            lntervieweeAddress: "拜访象地址",//
            reservationTime: "预约拜访日期",
            //
        }
        var cph = {licenseplate:"车牌号"}

        for(const CPH in cph){
            let lcsp =this.state.headData[CPH]
            if(( lcsp== ""|| lcsp == null) && this.state.headData.visitType=="车辆"){
                this.state.errormsg = cph[CPH] + "不能为空"
                this.setState({
                    errormsg: cph[CPH] + "不能为空"
                })

                return false
            }
        }


        for (const headkey in headobj) {

            let a = this.state.headData[headkey]

            if (a == null || a == "" ) {
                this.state.errormsg = headobj[headkey] + "不能为空"
                this.setState({
                    errormsg: headobj[headkey] + "不能为空"
                })

                return false

            }

        }
        //1是保存 2是提交
        if (varl == '2' || varl == '1') {
            if (arrObj.length == 0) {
                this.state.errormsg = "请维护访客人员信息,并保存,再提交申请"
                this.setState({

                    errormsg: "请维护访客人员信息,并保存,再提交申请"
                })

                return false
            }
            for (var field in arrObj) {

                var objfileid = arrObj[field];
                var visitorphotourl2 = objfileid.visitorphotourl
                var visitorCall2 = objfileid.visitorCall
                var note2 = objfileid.note
                var identificationNumber2 = objfileid.identificationNumber
                var visitorName2 = objfileid.visitorName
                var visitorAddress2 = objfileid.visitorAddress
                var re = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;  // 电话号码限制 正则表达式
                var result = re.test(visitorCall2);
                var hang = "第" + (Number(field) + 1) + "行,"
                var msg = ''
                // if(this.state.lfcode == "1" && !licenseplate2){
                //     msg += hang + '车牌号不能为空，请检查！ ';
                //     checkresult = false
                // }
                if (!result) {
                    msg += hang + '请输入正确的访客联系方式！ ';
                    checkresult = false
                }

                if (note2 && note2.length > 64) {
                    msg += hang + '人员信息备注不能超过64个字 ';
                    checkresult = false
                }
                if (identificationNumber2 && identificationNumber2.length > 18) {
                    msg += hang + '居民身份证号码不能超过18位,请检查 ';
                    checkresult = false

                }

                if (identificationNumber2 && identificationNumber2.length < 18) {
                    msg += hang + '居民身份证号码不能小于18位,请检查 ';
                    checkresult = false

                }
                if (!visitorName2) {
                    msg += hang + '访客姓名不能为空，请检查！ ';
                    checkresult = false

                }
                if (visitorName2.length > 64) {
                    msg += hang + '访客姓名不能超过64位,请检查 ';
                    checkresult = false

                }

                if (!visitorAddress2) {
                    msg += hang + '访客地址不能为空，请检查！ ';
                    checkresult = false

                }
                if (visitorAddress2.length > 64) {
                    msg += hang + '访客地址不能超过64位,请检查 ';
                    checkresult = false

                }

                if (!visitorphotourl2) {
                    msg += hang + '请上传身份证附件！ ';
                    checkresult = false

                }
                if(visitorphotourl2 && visitorphotourl2.length < 2){
                    msg += hang + '请上传身份证附件正反面！ ';
                    checkresult = false
                }


                if (!checkresult) {
                    this.state.errormsg = msg
                    this.setState({

                        errormsg: msg
                    })

                    break
                }

            }

        }

        return checkresult
    }
    //表单提交事件
    handleSubmit = e => {
        let { returnAddRowFunList } = this.state;
        let arrObj = []
        returnAddRowFunList.map(item => {
            let obj = {}
            obj[`visitorSex`] = this.state[`visitorSex${item.id}`]
            obj[`licenseplate`] = this.state[`licenseplate${item.id}`]
            obj[`visitorCall`] = this.state[`visitorCall${item.id}`]
            obj[`visitorName`] = this.state[`visitorName${item.id}`]
            obj[`note`] = this.state[`note${item.id}`]
            obj[`visitorAddress`] = this.state[`visitorAddress${item.id}`]
            obj[`createUserId`] = this.state[`createUserId${item.id}`]
            obj[`identificationNumber`] = this.state[`identificationNumber${item.id}`]

            console.log(this.state[`processedUrls${item.id}`])
            let src = this.state.imgFlag ? this.state[`visitorphotourl${item.id}`] : this.state[`processedUrls${item.id}`];
            let concatenatedSrc = "";
            if(src){
                console.log(typeof(src));
                if (typeof(src) != 'string'){
                    for(let i =0; i<src.length ; i++){
                        concatenatedSrc += src[i].src?src[i].src:src[i];
                        if (i < src.length - 1) {
                            concatenatedSrc += ";";
                        }
                    }
                }else{
                    concatenatedSrc += src;
                    // concatenatedSrc += ';'+ this.state[`visitorphotourl${item.id}`][1].src
                }
                obj[`visitorphotourl`] = concatenatedSrc;
            }

            obj[`createUserId`] = JSON.parse(localStorage.getItem("userInfo")).sfzh
            obj[`createUserName`] = JSON.parse(localStorage.getItem("userInfo")).ryxm
            arrObj = [...arrObj, obj]
        });

        //阻止表单提交行为
        e.preventDefault()
        // 表单数据
            const url = NEW_YGK_URL + 'yysqRecordController/saveData';

            var datajsom = {
                mpAppReservationRecord: this.state.headData,
                MpAppReservationRecordMxList: arrObj
            }

            if (this.state.yhxx) {
                let databf = this.state.headData
                databf.createUserId = this.state.yhxx.idno
                databf.createUserName = this.state.yhxx.name
                this.setState({
                    databf
                })
            }

            if (this.checkData("1") || this.checkData("2")) {
                Axios({
                    contentType: "application/json;charset=UTF-8",
                    headers: { 'Content-Type': 'application/json;charset=UTF-8' },
                    method: 'post',
                    url: url,
                    data: JSON.stringify(datajsom),
                    timeout: 900000, //这个就是重点
                    //这部分非常重要，否则formdata会被转格式
                    transformRequest: [function () { return JSON.stringify(datajsom) }],
                }).then((res) => {
                    Toast.hide();
                    let returnSource = res.data

                    if (res.status == 200 && returnSource.msg == "保存成功!") {
                        this.setState({
                            ifSave: true,
                            headData: returnSource.data.mpAppReservationRecord,
                            ryxxlist: returnSource.data.MpAppReservationRecordMxList

                        })
                        Toast.success(returnSource.msg, 1);
                    } else {
                        Toast.fail(returnSource.msg, 5);
                    }
                })
            }
            else {
                Toast.hide();
                Toast.info(this.state.errormsg)
            }
    };
    componentDidRecover = () => {
        // 这里可以更新业务数据
        console.log('List recovered')
    }
    componentDidMount = () => {

        //获取预约位置字典
        this.getYysqLocation()
        var type = this.props.location.query.type
        let isViewedit = true;
        let editdata = this.state.headData
        if (type === 'edit') {
            editdata = this.props.location.query.datasj
            this.setState({
                headData: editdata

            })
            this.getMX(this.props.location.query.datasj)
        }
        if (type === 'apply') {
            let { returnAddRowFunList } = this.state;
            let addId = 1
            let obj = {}
            obj[`id`] = `${addId}`
            let stateObj = {}
            stateObj[`createUserId${addId}`] = ''
            stateObj[`identificationNumber${addId}`] = ''
            stateObj[`note${addId}`] = ''
            stateObj[`visitorAddress${addId}`] = ''
            stateObj[`visitorCall${addId}`] = ''
            stateObj[`visitorName${addId}`] = ''
            stateObj[`visitorSex${addId}`] = ''
            stateObj[`visitorphotourl${addId}`] = []
            // stateObj[`url${addId}`] = ''
            //会增加userName1，passWord1，....的state属性，用于提交的时候获取。
            this.setState(stateObj)
            this.setState({
                returnAddRowFunList: [...returnAddRowFunList, obj],
            })
            this.state.sizeMX = addId
            this.setState({
                sizeMX: addId,
            })
        }
        if (type === 'view') {
            isViewedit = false
        }

        var yhdata = this.props.location.query.yhxx
        let yhxxinfo = {
            idno: yhdata.idno,
            name: yhdata.name
        }
        this.state.yhxx = yhxxinfo
        this.setState({
            yhxx: yhxxinfo

        })

    }
    async getMX(item) {
        var guid = item.reservationId
        var dateNow
        if (item.reservationTime) {
            dateNow = new Date(item.reservationTime)
        } else {
            dateNow = new Date()
        }
        var e1 = item.lntervieweeType
        var list = this.state.ofdjlx
        for (var itembf in list) {
            if (list[itembf].label == e1) { //item 表示Json串中的属性，如'name'
                this.state.bfcode = [list[itembf].value]
                this.setState({
                    bfcode: [list[itembf].value]
                })

                break;

            }
        }
        var e2 = item.visitType
        var listnew = this.state.lfdjlx

        for (var itemlf in listnew) {
            if (listnew[itemlf].label == e2) { //item 表示Json串中的属性，如'name'
                this.state.lfcode = [list[itemlf].value]
                this.setState({
                    lfcode: [list[itemlf].value]
                })
                break;

            }
        }
        var e3 = item.positionCode
        this.state.crcode = [e3]
        this.setState({
            crcode: [e3]
        })


        this.setState.yyrq = dateNow
        this.setState({
            yyrq: dateNow
        })

        const queyMxurl = NEW_YGK_URL + 'yysqRecordController/queyMx';
        var params = new URLSearchParams();
        params.append("guid", guid);

        Axios({
            method: 'post',
            url: queyMxurl,
            data: params
        }).then((res) => {
            if (res.status == 200) {

                var array = res.data
                if (array && array.length != 0) {
                    for (let index = 0; index < array.length; index++) {
                        let { returnAddRowFunList } = this.state;
                        let addId = index + 1
                        const element = array[index];
                        let obj = {}
                        obj[`id`] = `${addId}`
                        // if(index!=0){
                        //     obj[`id`] = `${addId}`
                        // }

                        let stateObj = {}

                        stateObj[`createUserId${addId}`] = element.createUserId
                        stateObj[`identificationNumber${addId}`] = element.identificationNumber
                        stateObj[`note${addId}`] = element.note
                        stateObj[`visitorAddress${addId}`] = element.visitorAddress
                        stateObj[`visitorCall${addId}`] = element.visitorCall
                        stateObj[`visitorName${addId}`] = element.visitorName
                        stateObj[`visitorSex${addId}`] = element.visitorSex

                        stateObj[`visitorphotourl${addId}`] = element.processedUrls?element.processedUrls:[]
                        stateObj[`processedUrls${addId}`] = element.visitorphotourl
                        stateObj[`sexdata${addId}`] = element.visitorSex == '男' ? ['0'] : ['1']
                        //会增加userName1，passWord1，....的state属性，用于提交的时候获取。
                        this.setState(stateObj)
                        this.setState({
                            returnAddRowFunList: [...returnAddRowFunList, obj],
                        })
                        this.state.sizeMX = addId
                        this.setState({
                            sizeMX: addId,

                        })
                    }
                } else {
                    let { returnAddRowFunList } = this.state;
                    let addId = 1
                    let obj = {}
                    obj[`id`] = `${addId}`
                    let stateObj = {}
                    stateObj[`createUserId${addId}`] = ''
                    stateObj[`identificationNumber${addId}`] = ''
                    stateObj[`note${addId}`] = ''
                    stateObj[`visitorAddress${addId}`] = ''
                    stateObj[`visitorCall${addId}`] = ''
                    stateObj[`visitorName${addId}`] = ''
                    stateObj[`visitorSex${addId}`] = ''
                    stateObj[`visitorphotourl${addId}`] = []
                    //会增加userName1，passWord1，....的state属性，用于提交的时候获取。
                    this.setState(stateObj)
                    this.setState({
                        returnAddRowFunList: [...returnAddRowFunList, obj],
                    })
                    this.state.sizeMX = addId
                    this.setState({
                        sizeMX: addId,
                    })

                }

                this.forceUpdate()
            }
        });
        this.forceUpdate()
    }
    async getYysqLocation() {
        const queylocationurl = NEW_YGK_URL + 'yysqRecordController/getyysqlocation';
        var params = new URLSearchParams();
        params.append("lfdxlx", this.state.lfdxlx);
        Axios({
            method: 'post',
            url: queylocationurl,
            data: params
        }).then((res) => {
            if (res.status == 200) {
                this.state.locationzd = res.data
                var jsonCode = JSON.stringify(res.data)
                jsonCode = jsonCode.replaceAll("LABEL", 'label').replaceAll("VALUE", 'value')
                jsonCode = JSON.parse(jsonCode)
                this.state.locationzd = jsonCode
                this.setState({
                    locationzd: jsonCode,
                });
            }
        });


    }
    chooseDate = (value, field) => {
        let datatemp = this.state.headData
        datatemp[field] = value
        this.setState({
            datatemp
        })

        var choosedateNow = new Date(value)
        this.setState.yyrq = choosedateNow
        this.setState({
            yyrq: choosedateNow
        })


    }

    dwsearch = (val) => {
        this.state.tempOrg = val
        this.setState({
            tempOrg: val
        }, () => {
            this.querydeptqueyDwmx();
        });
    }
    /**
  * 获取单位的拜访信息
  */
    async querydeptqueyDwmx() {
        const url = NEW_YGK_URL + 'yysqRecordController/queyDwmx';
        let datatemp = this.state.headData
        var bflx = datatemp.lntervieweeType
        var params = new URLSearchParams();
        params.append("name", this.state.tempOrg);
        params.append("bflx", bflx);



        Toast.loading("加载中...", 0, null, true);
        await Axios({
            method: 'post',
            url: url,
            data: params

        }).then((res) => {
            Toast.hide();
            if (res.status === 200) {
                var list = [];
                console.log(res)
                console.log(res.data)
                if (res.data) {
                    list = res.data
                    this.state.dwlist = list
                    console.log(list)
                    // this.state.maxcols=res.data.js
                    this.setState({
                        dwlist: list,
                    });
                    if (list.length === 0) {
                        Toast.info("暂无信息")
                        this.setState({ InputBfdz: true , InputBfmc: true});
                    }
                } else {
                    Toast.info("暂无信息")
                    this.setState({ InputBfdz: true , InputBfmc: true});
                }


            }
        });
    }
    setdwlist = (e) => {
        let datatemp = this.state.headData
        var bflx = datatemp.lntervieweeType
        if (bflx !== '单位') {
            return this.state.dwlist.map(i => (
                <List>
                    <RadioItem style={{
                        width: "100%"
                    }} key={Math.random()} onClick={() => this.dwChange(i)}  >

                        <List.Item arrow="horizontal"> 姓名:{i.CARDHOLDER_NAME} 住址： {i.PERMISSION_GROUP_NAME.replace(/(\d{2}-)(.*)(-\d{4})/, "$1*****$3")}</List.Item>
                    </RadioItem></List>
            ))
        } else {
            return this.state.dwlist.map(i => (
                <List>
                    <RadioItem key={Math.random()} onClick={() => this.dwChange(i)}  >
                        <List.Item arrow="horizontal">  {i.PJ}</List.Item>
                    </RadioItem>
                </List>
            ))
        }

    }
    //获取单位信息
    dwChange = (e) => {
        //tempOrg
        let databf = this.state.headData
        var bflx = databf.lntervieweeType
        var orgid = ""
        if (bflx === '单位') {
            //databf.lntervieweeAddress=e.UNIT_NAME
            databf.lntervieweeAddress = e.UNIT_SHORT_NAME

            orgid = e.UNIT_ID
            this.state.orgcode = orgid

        } else {
            databf.lntervieweeAddress = e.PERMISSION_GROUP_NAME
            orgid = e.CARD_NO
            this.state.orgcode = e.CARD_NO
            databf.lntervieweeName = e.CARDHOLDER_NAME
            databf.lntervieweeCall = e.PHONE_NO
        }
        this.setState({
            dwsc: false,
            orgcode: orgid,
            databf
        })
    }

    setuserlist = (e) => {
        return this.state.userlist.map(i => (
            <List><RadioItem key={Math.random()} onClick={() => this.userChange(i)}  >
                <List.Item arrow="horizontal">
                    {i.PERSON_NAME}    </List.Item>
            </RadioItem></List>

        ))
    }
    //获取单位信息
    userChange = (e) => {
        //tempOrg
        let databf = this.state.headData
        databf.lntervieweeCall = e.YHLXDH?e.YHLXDH:''
        databf.lntervieweeName = e.PERSON_NAME
        this.setState({
            databf,
            grsc: false
        })
    }
    usersearch = (val) => {
        this.setState({
        }, () => {
            const url = NEW_YGK_URL + 'yysqRecordController/selectDwForuser';
            var params = new URLSearchParams();
            let datatemp = this.state.headData

            params.append("orgname", datatemp.lntervieweeAddress);
            params.append("name", val);
            Toast.loading("加载中...", 0, null, true);
            Axios({
                method: 'post',
                url: url,
                data: params

            }).then((res) => {
                Toast.hide();
                if (res.status === 200) {
                    var list = [];
                    if (res.data) {
                        list = res.data
                        this.state.userlist = list
                        this.setState({
                            userlist: list,
                        });
                        if (list.length === 0) {
                            Toast.info("暂无信息")
                        }
                    } else {
                        Toast.info("暂无信息")
                    }


                } else {
                    //Toast.errormsg()
                }
            });
            //this.querydeptUser(this);
        });
    }
    /**
* 获取单位的拜访信息
*/
    async querydeptUser(val) {
        const url = NEW_YGK_URL + 'yysqRecordController/queyDwUser';
        var params = new URLSearchParams();
        console.log(val)
        let datatemp = val.state.headData

        params.append("name", datatemp.lntervieweeAddress);//机构名称
        params.append("type", datatemp.lntervieweeType);//拜访类型
        Toast.loading("加载中...", 0, null, true);
        await Axios({
            method: 'post',
            url: url,
            data: params

        }).then((res) => {
            Toast.hide();
            if (res.status === 200) {
                var list = [];
                if (res.data) {
                    list = res.data
                    this.state.userlist = list
                    this.setState({
                        userlist: list,
                    });
                    if (list.length === 0) {
                        Toast.info("暂无信息")
                    }
                } else {
                    Toast.info("暂无信息")
                }


            } else {
                //Toast.errormsg()
            }
        });
    }
    onClose = key => () => {
        if ("dwsc" === key) {
            this.state.dwlist = []
            this.setState({
                dwlist: [],
            });
        }
        this.setState({
            [key]: false,
        });
    }
    // onChange = (value, label) => {
        // console.log("改变值")
        // console.log(value)
        // console.log(label)

        // this.setState({
        //     value,
        // });
    // };
    //判断是否是微信浏览器的函数
    isWeiXin() {
        //window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
        var ua = window.navigator.userAgent.toLowerCase();
        //通过正则表达式匹配ua中是否含有MicroMessenger字符串
        if (ua.match(/MicroMessenger/i) == 'micromessenger') {
            return true;
        } else {
            return false;
        }
    }

    genID = (length) => {
        return Number(Math.random().toString().substr(3, length) + Date.now()).toString(36);
    }
    setmxList = () => {
        let thatthis = this
        return (
            <div>

                <List renderHeader={() => '人员信息'}>

                    <List.Item>
                        <InputItem
                            type="text"
                            value={this.state.A}
                            // editable={false}
                            ref={el => this.labelFocusInst = el}
                            onChange={(value, item) => this.changngemx(value, item, 'visitorName')}
                        ><div>申请人</div></InputItem>
                        <InputItem
                            type="text"
                            value={this.state.A}
                            // editable={false}
                            onChange={(value) => this.setState({ sqdw: value })}
                        ><div>申请单位</div></InputItem>
                    </List.Item>




                </List>
            </div>
        )


    }


    /* 点击确定按钮 */
    _clickChooseOk = () => {
        if(!this.state.ifSave){
            Toast.info('请先保存再提交！', 2)
            return;
        }
        var thattjis = this
        // thattjis.checkData('1')
        if (thattjis.checkData('2')) {
            const url = NEW_YGK_URL + 'yysqRecordController' + '/submitData';
            var params = new URLSearchParams();
            params.append("ids", thattjis.state.headData.reservationId);
            Axios({
                method: 'post',
                url: url,
                data: params
            }).then((res) => {
                Toast.info('提交成功', 2)
                setTimeout(function () {
                    thattjis.props.history.goBack()
                }, 2000);
            })


        } else {
            Toast.hide();
            Toast.info(thattjis.state.errormsg)
        }
    }
    //*拜访对象类型：
    handlebflxChange = (e) => {
        var datasj = 0
        if (e.length == '1') {
            datasj = e[0]
        }
        const list = this.state.ofdjlx
        for (var item in list) {
            if (list[item].value == datasj) { //item 表示Json串中的属性，如'name'
                this.state.lntervieweeType = list[item].value
                let databf = this.state.headData
                this.state.bfcode = e
                this.setState({
                    bfcode: e
                })

                databf.lntervieweeType = list[item].label
                this.setState({
                    databf
                })
                break;

            }
        }

        this.forceUpdate()
    }

    handleCrdChange = (e) => {   //出入地
        var datasj = 0
        if (e.length == '1') {
            datasj = e[0]
        }
        const list = this.state.locationzd
        for (var item in list) {
            if (list[item].value == datasj) { //item 表示Json串中的属性，如'name'
                let databf = this.state.headData
                this.state.headData.positionCode = list[item].value
                this.state.headData.postionName = list[item].label
                console.log(list[item])

                this.state.crcode = e
                this.setState({
                    crcode: e
                })

                databf.positionCode = datasj
                this.setState({
                    databf
                })
                break;

            }
        }

        this.forceUpdate()

    }


    //*来访对象类型： val, extend.items
    handlelflxChange = (e) => {
        var datasj = 0

        if (e.length == '1') {
            datasj = e[0]
        }

        const list = this.state.lfdjlx
        for (var item in list) {
            if (list[item].value == datasj) { //item 表示Json串中的属性，如'name'
                this.state.lntervieweeType = list[item].value
                this.state.lfdxlx = list[item].label
                if(this.state.lfdxlx == "个人"){
                    this.state.lfdxlx = "人员"
                }
                let databf = this.state.headData
                this.state.lfcode = e
                this.setState({
                    lfcode: e
                })
                if (this.state.lfcode == "1") {
                    this.state.showInput = true;
                }else{
                    this.state.showInput = false;
                }
                databf.visitType = list[item].label
                this.setState({
                    databf
                });
                this.getYysqLocation();
                break;

            }
        }

        this.forceUpdate()

    }
    //val 实际值 var2 对应的字段
    changngedata = (val, var2) => {
        let datatemp = this.state.headData
        if(var2 == "licenseplate"){
            const uppercasedValue = val.toUpperCase();//强制大写
            datatemp[var2] = uppercasedValue
        }else{
            datatemp[var2] = val
        }
        this.setState({
            datatemp
        })
    }
    //value,item,'visitorName'  值 index
    changngemxdata = (val, var2, var3) => {
        let addid = var2
        if (var3 === 'visitorSex') {
        }

        let stateObj = {}
        stateObj[`${var3}${addid}`] = val
        this.setState(stateObj)
    }

    componentDidUpdate(prevProps, prevState) {
        const { value1, value2 } = this.state;
        if (prevState[`${value2}${value1}`] !== this.state[`${value2}${value1}`]) {
          console.log(`stateObj[${value2}${value1}] 发生了变化`);
          this.state.imgFlag = true
        }
      }

     onChangeFile = (files, type, index, val1, val2) => {
        console.log(files, type, index, val1, val2);
        var str = [];
        const value1 = val1;
        const value2 = val2;
        this.setState({ value1, value2 });
        if (type !== "remove") {
            const promises = files.map((file) => {
                if (file.file) {
                  return lrz(file.url, { quality: 0.1 }).then((rst) => {
                    console.log(rst);
                    let newFile = new File([rst.file], this.genID(16) + "." + file.file.name.split(".")[1], {
                      type: file.file.type,
                      lastModified: Date.now(),
                    });

                    var arr = [{ orientation: 1, url: rst.base64, file: newFile }];
                    return this.uploadFile(arr[0]);
                  });
                } else {
                  // 如果没有url，返回一个已解决的Promise，以确保Promise.all仍然等待所有处理完成
                  return Promise.resolve();
                }
              });

          Promise.all(promises)
            .then((urls) => {
              urls.forEach((url, i) => {
                files[i].src = url;
                str.push(files[i]);
              });

              let stateObj = {};

              stateObj[`${val2}${val1}`] = str;


              this.setState({
                files: str,
                ...stateObj,
              });

              console.log(this.state.files, "21111111");
              console.log(stateObj);
            })
            .catch((error) => {
              console.error("Error uploading file:", error);
            });
        } else {
            this.state[`visitorphotourl${val1}`].splice(index, 1)
            if(this.state[`processedUrls${val1}`]){
                let newProcessedUrls = this.state[`processedUrls${val1}`].split(';');
                if(newProcessedUrls.length > 1){
                this.state[`processedUrls${val1}`] = index === '0' ? newProcessedUrls[0] : newProcessedUrls[1];
                }
            }
            this.setState({
                files,
            });
        };
    }

      uploadFile = async (fileInfo) => {
        return new Promise(async (resolve, reject) => {
          try {
            this.state.fileUrl = "";
            const url = NEW_YGK_URL + 'yysqRecordController/getFileUrl';
            let params = new FormData();
            params.append("affix", fileInfo.file);

            const res = await Axios({
              method: 'post',
              url: url,
              data: params,
              timeout: 900000,
            });

            this.state.fileUrl = res.data.path;
            console.log(this.state.fileUrl);
            resolve(this.state.fileUrl);
          } catch (error) {
            reject(error);
          }
        });
      };


    changngxbdata = (e, var2) => {
        let stateObj = {}
        stateObj[`visitorSex${var2}`] = e[0] == '0' ? '男' : '女'
        stateObj[`sexdata${var2}`] = e
        //会增加userName1，passWord1，....的state属性，用于提交的时候获取。
        this.setState(stateObj)
        this.forceUpdate()
    }
    //弹窗
    showModal = key => (e) => {
        e.preventDefault(); // 修复 Android 上点击穿透
        var flag = true
        var msg = '请输入单位名称'
        if (key === 'dwsc') {
            let datatemp = this.state.headData
            var bflx = datatemp.lntervieweeType
            if (!bflx) {
                flag = false
                Toast.info('请先选择拜访对象类型', 2)
            }
            if ("个人" === bflx) {
                msg = "请输入用户姓名"
            }

            this.state.showmsg = msg
            this.setState({
                showmsg: msg
            });

            this.state.dwlist = []
            this.setState({
                showmsg: msg,
                dwlist: []
            });
        }

        if (key === 'grsc') {
            let datatemp = this.state.headData
            var bflx = datatemp.lntervieweeType
            this.state.dwlist = []
            this.setState({
                dwlist: [],

            });
            if ("个人" === bflx) {
                flag = false
                Toast.info('拜访对象类型为个人不用选择拜访对象名称', 2)
            } else {

                const url = NEW_YGK_URL + 'yysqRecordController/selectDwForuserAll';
                var params = new URLSearchParams();

                params.append("orgname", datatemp.lntervieweeAddress);//机构名称
                params.append("type", datatemp.lntervieweeType);//拜访类型
                Toast.loading("加载中...", 0, null, true);
                Axios({
                    method: 'post',
                    url: url,
                    data: params

                }).then((res) => {
                    Toast.hide();
                    if (res.status === 200) {
                        var list = [];
                        if (res.data) {
                            list = res.data
                            this.state.userlist = list
                            this.setState({
                                userlist: list,
                            });
                            if (list.length === 0) {
                                Toast.info("暂无信息")
                                this.setState({ InputBfmc: true });
                                console.log(this.state.InputBfmc,"12121212")
                            }
                        } else {
                            Toast.info("暂无信息")
                            this.setState({ InputBfmc: true });
                        }


                    } else {
                        //Toast.errormsg()
                    }
                })



            }



        }

        if (flag) {
            this.setState({
                [key]: true,
            });
        }


    }
    //   onClose = key => () => {
    //     this.setState({
    //       [key]: false,
    //     });
    //   }
    openOrgsel = () => {
    }
    onChangeorg = (value) => {
        console.log(value[0]);
        var options = this.state.deptqueyDwmx
        var orgarray = []
        let databf = this.state.headData
        for (let index1 = 0; index1 < value.length; index1++) {
            var Optionsele = options.find(option => option.value === value[index1])
            if (index1 === value.length - 1) {
                console.log(Optionsele.label)
                //this.state.orgcode=Optionsele.label
                this.state.orgname = value
                console.log(this.state.orgname)
                databf.lntervieweeAddress = Optionsele.label
                this.setState({
                    //databf,
                    //orgcode:Optionsele.value,
                    orgname: value
                })
                this.forceUpdate()
                break
                //this.queryPackuser(Optionsele.value)


            } else {
                options = Optionsele.children
            }
        }

    }

    hidePhoneNumber(phoneNumber){
        if(phoneNumber){
        const length = phoneNumber.length;
        const flag = phoneNumber.startsWith('1');
        if(flag){
            const hiddenDigits  = phoneNumber.replace(/(\d{3})\d{4}(\d{4})/, "$1****$2");
            return hiddenDigits;
        }else if(length>4){
            const hiddenDigits = '*'.repeat(length - 4);
            return hiddenDigits + phoneNumber.substring(length - 4);
        }}
        return phoneNumber
    }

    render() {
        return (
            <div style={{ marginTop: ".89rem" }}>
                {/* 导航栏#0F84D6 */}
                <NavBar
                    mode="dark"
                    icon={<Icon type="left" />}

                    onLeftClick={() => this.props.history.go(-1)}
                >预约申请</NavBar>
                <form onSubmit={this.handleSubmit}>
                    <div className="divmagin">
                        {/* <Tabs tabs={tabs}
                            initialPage={0}
                            // onChange={(tab, index) => { }}
                            onChange={this.handleTabChange}
                            onTabClick={(tab, index) => { }}
                        > */}
                            <div>
                                <List
                                renderHeader={() => '基本信息'
                                } >

                                    <Picker

                                        data={this.state.lfdjlx}
                                        cols={1}
                                        editable={this.state.editviewable}
                                        onChange={this.handlelflxChange}

                                        className="forss"
                                        value={this.state.lfcode}
                                        extra="请先选择来访类型"
                                    >
                                        <List.Item arrow="horizontal"><span className="error">*</span>来访对象类型:</List.Item>
                                    </Picker>
                                    <div>
                                        {this.state.showInput && (<InputItem
                                            type="text" placeholder="请输入车牌号"
                                            value={this.state.headData.licenseplate}
                                            editable={this.state.editviewable}

                                            onChange={(value) => this.changngedata(value, 'licenseplate')}
                                        >
                                            <div>车牌号：</div> </InputItem>
                                        )}</div>

                                    {
                                        <Picker
                                            data={this.state.locationzd}
                                            cols={1}
                                            onChange={this.handleCrdChange}
                                            editable={this.state.editviewable}
                                            className="forss"
                                            value={this.state.crcode}

                                            extra="请选择"
                                        >
                                            <List.Item arrow="horizontal"><span className="error">*</span>出入地</List.Item>
                                        </Picker>
                            /* <InputItem
                                    type="select" placeholder ="请选择出入地"
                                    value={this.state.headData.positionCode}
                                     editable={this.state.editviewable}
                                    ref={el => this.labelFocusInst = el}

                                    onChange={(value)=>this.changngedata(value,'positionCode')}
                                ><div><font color="red">*</font>出入地：</div></InputItem>
                             */}
                                    {


                                        <Picker
                                            data={this.state.ofdjlx}
                                            cols={1}
                                            onChange={this.handlebflxChange}
                                            editable={this.state.editviewable}
                                            className="forss"
                                            value={this.state.bfcode}
                                            extra="请选择"
                                        >
                                            <List.Item arrow="horizontal"><span className="error">*</span>拜访对象类型</List.Item>
                                        </Picker>}
                                    {<InputItem
                                        type="text"
                                        editable={this.state.InputBfdz}
                                        placeholder="请选择"
                                        // onClick={this.showModal('dwsc')}
                                        value={this.state.headData.lntervieweeAddress.replace(/(\d{2}-)(.*)(-\d{4})/, "$1*****$3")}
                                        onChange={(value) => this.changngedata(value, 'lntervieweeAddress')}
                                        extra={<Icon type="search" size="xs" onClick={this.showModal('dwsc')} />}
                                    ><font color="red">*</font>拜访对象地址：</InputItem>}
                                    {
                                        <Modal
                                            popup
                                            visible={this.state.dwsc}
                                            cols={1}
                                            value={this.state.tempOrg}
                                            onClose={this.onClose('dwsc')}
                                            animationType="slide-up"
                                            style={{
                                                height: "280px",
                                                overflow: "auto"
                                            }}
                                        >
                                            <SearchBar placeholder={this.state.showmsg} cancelText="查询" onCancel={this.dwsearch} />
                                            {this.setdwlist()}

                                        </Modal>
                                    }



                                    <InputItem

                                        //class='am-input-label am-input-label-8'
                                        type="text"
                                        // onClick={this.showModal('grsc')}
                                        value={this.state.headData.lntervieweeName}
                                        placeholder="请选择"
                                        editable={this.state.InputBfmc}
                                        onChange={(value) => this.changngedata(value, 'lntervieweeName')}
                                        extra={<Icon type="search" size="xs" onClick={this.showModal('grsc')} />}
                                    ><font color="red">*</font>拜访对象名称：</InputItem>

                                    {
                                        <Modal
                                            popup
                                            visible={this.state.grsc}
                                            cols={1}
                                            value={this.state.tempUser}
                                            onClose={this.onClose('grsc')}
                                            animationType="slide-up"
                                            style={{
                                                height: "280px",
                                                overflow: "auto"
                                            }}
                                        >
                                            <SearchBar placeholder="请输入人员姓名查找" cancelText="查询" onCancel={this.usersearch} />
                                            {this.setuserlist()}

                                        </Modal>
                                    }



                                    <InputItem
                                        type="text"
                                        value={this.hidePhoneNumber(this.state.headData.lntervieweeCall)}
                                        onChange={(value) => this.changngedata(value, 'lntervieweeCall')}
                                        editable={this.state.editviewable}
                                    ><font color="red">*</font>拜访对象联系方式：</InputItem>











                                    <DatePicker
                                        type="datetime"
                                        precision='minute'
                                        minDate={minDate}
                                        // mode="datetime"
                                        title="请选择预约日期"
                                        //format="yyyy-MM-dd HH:mm:ss"
                                        //extra="请选择预约日期" format="yyyy-MM-dd"
                                        value={this.state.yyrq}
                                        onChange={(value) => this.chooseDate(value, 'reservationTime')}
                                    >
                                        <List.Item arrow="horizontal"><span className="error">*</span>预约拜访日期:</List.Item>
                                    </DatePicker>


                                    {/* <InputItem
                                    type="date"
                                    value={this.state.headData.reservationTime}
                                    editable={this.state.editviewable}

                                    onChange={(value)=>this.changngedata(value,'reservationTime')}
                                ><div><font color="red">*</font>预约拜访时间：</div>
                                </InputItem>
                                */}

                                    <TextareaItem
                                        title="拜访事由" placeholder="请输入"
                                        data-seed="logId"
                                        ref={el => this.autoFocusInst = el}
                                        editable={this.state.editviewable}

                                        value={this.state.headData.subjectMatter}
                                        onChange={(value) => this.changngedata(value, 'subjectMatter')}
                                        autoHeight
                                    />
                                    <TextareaItem
                                        title="备注："
                                        data-seed="logId" placeholder="请输入"
                                        editable={this.state.editviewable}
                                        ref={el => this.autoFocusInst = el}
                                        value={this.state.headData.note}
                                        onChange={(value) => this.changngedata(value, 'note')}
                                        autoHeight
                                    />

                                <div>
                                {/* // {this.setmxList()} */}
                                {this.returnAddRowFun()}


                            </div>
                                </List>


                            </div>

                        {/* </Tabs> */}
                    </div>
                    <div className="fixed-buttons" display=''>
                        <div className="mxitemson">
                            <button className="submit" type="submit">
                                保  存
                            </button>
                            <div style={{ width: '20px' }}></div>
                            <button className="save" type="button" onClick={this._clickChooseOk}>
                                提 交
                            </button>

                        </div>
                    </div>
                </form>

            </div>
        )
    }
}
