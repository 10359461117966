import './applycardaudit.css'
import React, { Component } from 'react'
import { NavBar, Icon, List,Card, WingBlank, WhiteSpace,Toast } from 'antd-mobile';
import { NEW_YGK_URL, Axios } from '../../../utils/url';
import ygk from '../../../assets/images/ygk.png'
const Item = List.Item;
const Brief = Item.Brief;
export default class ApplyCardAudit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            isLoaded: false,
        }
    }

    componentDidMount = () => {
        this.getList();
    }

    async getList() {
        // let ryobject = JSON.parse(localStorage.getItem('userInfo'));
        const url = NEW_YGK_URL + 'cardApplyInfo/getCardApplyAuditList';
        var params = new URLSearchParams();
        // params.append("idCardNo", ryobject.sfzh);
        await Axios({
            method: 'post',
            url: url,
            data: params
        }).then((res) => {
            console.log("开始获取待审核数据》》》",res);
            if (res.status === 200) {
                this.setState({
                    list: res.data.records,
                    isLoaded: true
                });
            }
        });
    }

    setMapList = () => {
        if (!this.state.isLoaded) {
            return null;
        } else {
            return this.state.list.map((item, key) => (
                <WingBlank size="lg" key={key}>
                <WhiteSpace size="lg" />
                <Card onClick={() => this.handleJump(item)}>
                    <Card.Header
                        title={item.apply_person}
                        thumb={ygk}
                        extra={<span>{'申请类型：' + item.apply_card_type}</span>}
                    />
                    <Card.Body>
                        <div>{item.code_content}</div>
                    </Card.Body>
                    <Card.Footer content={'申请时间：' + item.apply_time.slice(0,10) } extra={<div>{'当前状态：' + item.status}</div>}  />
                </Card>
                <WhiteSpace size="lg" />
            </WingBlank>
            ))
        }
    }

    handleJump = (e) => {
        this.props.history.push({ pathname : "./applyCardAuditShow" , query : {apply_id:e.apply_id}});
    }

    render() {
        return (
            <div style={{ marginTop: ".89rem" }}>
                {/* 导航栏#0F84D6 */}
                <NavBar
                    mode="dark"
                    icon={<Icon type="left" />}
                    // rightContent={[
                    //     <div>申请</div>
                    // ]}
                    onLeftClick={() => this.props.history.goBack()}
                >一卡通审核</NavBar>
                <div className="divmagin">
                    {this.setMapList()}
                </div>
            </div>
        )
    }
}
