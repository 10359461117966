import './wyfkjf.css'
import React, { Component } from 'react'
import { BASE_URL,WY_URL, YL_URL,PAGE_URL, Axios } from '../../../utils/url'
import ygk from '../../../assets/images/ygk.png'
import Wxzf from '../../../assets/images/wxzf.png'
import Zfbzf from '../../../assets/images/zfbzf.png'
import { NavBar, Icon, Card, WingBlank, WhiteSpace, Toast, List, Button, Modal, Radio } from 'antd-mobile';
const RadioItem = Radio.RadioItem;
export default class Wyfkjf extends Component {
    constructor(props) {
        super(props);
        this.state = ({
            ygkxx: [],
            isLoading: false,
            modal: false,
            value: "",
            czje: "",
            item: "",
            yhxm: "",
            yhid: ""
        });
    }
    componentDidMount = () => {
        if (this.isWeiXin()) {
            this.setState({
                value:"WXPay.jsPay"
            });
        } else {
            this.setState({
                value:"trade.precreate"
            });
        }
        var userInfo = JSON.parse(localStorage.getItem("userInfo"));
        if (userInfo === null) {
            const openid = this.props.location.search;
            if (openid.length > 0) {
                var open = openid.split("&");
                var openidArr = open[0].split("=");
                var openid1 = openidArr[1];
                var params = new URLSearchParams();
                localStorage.setItem("openID", openid1);
                params.append('openid', openid1);
                Axios({
                    method: 'post',
                    url: BASE_URL+"/bRyzcController/getUserInfo",
                    data: params
                }).then((res) => {
                    localStorage.setItem("userInfo", JSON.stringify(res.data.userInfo));
                    localStorage.setItem("bshtoken", JSON.stringify(res.data.bshtoken));
                    userInfo = JSON.parse(localStorage.getItem("userInfo"));
                    this.setState({
                        yhxm: userInfo.ryxm,
                        yhid: userInfo.sfzh
                    })
                    this.getYgkxx(userInfo.sfzh);
                });

            }
        } else {
            this.setState({
                yhxm: userInfo.ryxm,
                yhid: userInfo.sfzh
            })
            this.getYgkxx(userInfo.sfzh);
        }


    }
    onClose = key => () => {
        this.setState({
            [key]: false,
        });
    }
    getGbfList = () => {
        if (!this.state.isLoaded) {
            return null;
        } else {
            return this.state.ygkxx.list.map((item, key) => (
                <WingBlank size="lg" key={key}>
                    <WhiteSpace size="lg" />
                    <Card onClick={() => this.handleOrder(item)}>
                        <Card.Header
                            title={item.a6}
                            thumb={ygk}
                            extra={<span>{item.sfjlxh}</span>}
                        />
                        <Card.Body>
                            <div>{item.sfzt}</div>
                        </Card.Body>
                        <Card.Footer content={item.sfxmmc} extra={<div>{"收费金额：" + item.je + "元"}</div>} />
                    </Card>
                    <WhiteSpace size="lg" />
                </WingBlank>
            ))


        }
    }
     //判断是否是微信浏览器的函数
     isWeiXin() {
        //window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
        var ua = window.navigator.userAgent.toLowerCase();
        //通过正则表达式匹配ua中是否含有MicroMessenger字符串
        if (ua.match(/MicroMessenger/i) == 'micromessenger') {
            return true;
        } else {
            return false;
        }
    }
    async getYgkxx(sfzh) {
        const url = WY_URL + 'bsfxmjlController/getClfkjf';
        var params = new URLSearchParams();
        // params.append("sfzh", "622323199208183117");
        params.append("sfzh", sfzh);
        Toast.loading("加载中...", 0, null, true);
        await Axios({
            method: 'post',
            url: url,
            data: params
        }).then((res) => {
            Toast.hide();
            console.log(res);
            if (res.status === 200) {
                this.setState({
                    ygkxx: res.data,
                    isLoaded: true
                });

            }
        });
    }
    handleOrder = (e) => {
        this.setState({
            modal: true,
            item: e
        });
    }
    // 获取选择车库信息
    onChange = (value, label) => {
        this.setState({
            value,
        });
    };

    //下单
    setOrder = () => {
        console.log(this.state.item);
        // if (this.state.czje === '') {
        //     Toast.info("请输入充值金额", 1, null, false);
        // } else
        if (this.state.value === '') {
            Toast.info("请选择支付类型", 1, null, false);
        } else {
            if (this.isWeiXin() && this.state.value === "trade.precreate") {
                let openId = localStorage.getItem("openID");
                //alert(openId);
                window.parent.frames.location.href = PAGE_URL+"?openId=" + openId+"&page=wyfkjf";
                return;
            }
            // const url = YL_URL + 'xiadanController/order';
            const userInfo = JSON.parse(localStorage.getItem("userInfo"));
            var url = "";
            if (this.isWeiXin()) {
                url = YL_URL + 'xiadanWXController/order'
            } else {
                url = YL_URL + 'xiadanController/order';
            }
            var params = new URLSearchParams();
            params.append('totalAmount', this.state.item.je);
            params.append('goodsId', this.state.item.sfjlxh);
            params.append('goodsName', "车辆罚款");
            params.append('id', this.state.item.sfjlxh);
            params.append('price', this.state.item.je);
            params.append('payMethod', this.state.value);
            params.append('ywbm', "WYFKJF");
            params.append('yhid',  userInfo.sfzh);
            params.append('yhxm',  userInfo.ryxm);
            Toast.loading("加载中...", 0, null, true);
            Axios({
                method: 'post',
                url: url,
                data: params
            }).then((res) => {
                Toast.hide();
                if (this.state.value == "WXPay.jsPay") {
                    if (res.status == '200') {
                        window.parent.frames.location.href = res.data.data;
                    } else {
                        alert("支付失败，请重新支付");
                    }
                } else {
                    if (res.status == '200') {
                        let jsonData = JSON.parse(res.data.data);
                        if (jsonData.errCode == 'SUCCESS' && jsonData.xdmsg == "下单成功!") {
                            let payJson = JSON.parse(jsonData.respStr);
                            this.setState((state, props) => {
                                return {
                                    zfdz: payJson.appPayRequest.qrCode,
                                    merOrderId: payJson.merOrderId
                                }
                            },
                                () => {
                                    //支付
                                    Modal.alert('确认支付？', '确认支付？', [
                                        { text: '取消', onPress: () => console.log('下次一定') },
                                        {
                                            text: '确定',
                                            onPress: () =>
                                                new Promise((resolve) => {
                                                    this.setState({
                                                        isPay: true
                                                    });
                                                    //跳转到登录
                                                    window.open(this.state.zfdz);
                                                    this.props.history.push({ pathname: '/yktgbfjfqr', query: { merOrderId: this.state.merOrderId } });
                                                    setTimeout(resolve, 500);
                                                }),
                                        },
                                    ])
                                }
                            )

                        } else {
                            alert("支付失败，请重新支付");
                        }
                    }
                }
            });
        }

    }
    render() {
        const { value } = this.state;
        const data = [
            //{ value: "WXPay.jsPay", label: '微信支付', img: Wxzf },
            //{ value: "trade.precreate", label: '支付宝支付', img: Zfbzf },
        ];
        if(this.isWeiXin()){
            var obj= {};
            obj.value = "WXPay.jsPay";
            obj.label = "微信支付";
            obj.img = Wxzf;
            data.push(obj);
            var obj1 = {};
            obj1.value = "trade.precreate";
            obj1.label = "支付宝支付";
            obj1.img = Zfbzf;
            data.push(obj1);
        }else{
            var obj= {};
            obj.value = "trade.precreate";
            obj.label = "支付宝支付";
            obj.img = Zfbzf;
            data.push(obj);
        }
        return (
            <div style={{ marginTop: ".89rem" }}>
                {/* 导航栏#0F84D6 */}
                <NavBar
                    mode="dark"
                    icon={<Icon type="left" />}
                    onLeftClick={() => this.props.history.goBack()}
                >罚款缴费</NavBar>
                <div className="kf">
                    {this.getGbfList()}

                </div>
                {/* 选择排号车库 */}
                <Modal
                    popup
                    visible={this.state.modal}
                    onClose={this.onClose('modal')}
                    animationType="slide-up"
                    afterClose={() => { console.log('afterClose'); }}
                >
                    {/* <List renderHeader={() => '充值金额'}>
                        <InputItem
                            type='number'
                            placeholder="请输入充值金额"
                            value={this.state.czje}
                            onChange={(value) => this.setState({ czje: value })}
                            extra="元"
                        >充值金额</InputItem>
                    </List> */}
                    <List renderHeader={() => '选择支付方式'}>
                        {data.map(i => (
                            <RadioItem key={i.value} checked={value === i.value} onChange={() => this.onChange(i.value, i.label)}>
                                <img src={i.img} />  {i.label}
                            </RadioItem>
                        ))}
                        <Button type="primary" onClick={this.setOrder}>确认</Button>
                    </List>
                </Modal>
            </div>
        )
    }
}
