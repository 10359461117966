import './yktqxxxcx.css'
import React, { Component } from 'react'
import { NavBar,Icon } from 'antd-mobile';
export default class Yktqxxxcx extends Component {
    render() {
        return (
            <div style={{ marginTop: ".89rem" }}>
            {/* 导航栏#0F84D6 */}
            <NavBar
              mode="dark"
              icon={<Icon type="left" />}
             onLeftClick={() => this.props.history.goBack()}
            >一卡通权限信息查询</NavBar>
            <div className="kf">正在开发中，敬请期待~</div>
            </div>
        )
    }
}
