import './clList.css'
import React, { Component } from 'react'
import { BASE_URL, Axios } from  '../../../utils/url'
import {NavBar,Icon,List, Toast,ActionSheet,Modal,NoticeBar} from 'antd-mobile'
const Item = List.Item;
const Brief = Item.Brief;
const isIPhone = new RegExp('\\biPhone\\b|\\biPod\\b', 'i').test(window.navigator.userAgent);
let wrapProps;
if (isIPhone) {
  wrapProps = {
    onTouchStart: e => e.preventDefault(),
  };
}
export default class index extends Component {
    constructor(props){
        super(props);
localStorage.removeItem('clxx')
        this.state = {
            cllist: [],
            isLoaded: false,
            yhid:"",
            yhxm:"",

        }
    }
    componentDidMount = (e) => {
        if(e){
            Toast.info("删除成功！")
        }
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        this.setState({
            yhxm: userInfo.ryxm,
            yhid: userInfo.sfzh
        })
        this.getClglList(userInfo.sfzh);

    }
    handleSq=()=>{
        this.props.history.push('./clcrsq');
    }
    //获取列表
    async getClglList(sfzh) {
        const url = BASE_URL+'bclcrsqbController/getClList';
        var params = new URLSearchParams();
        params.append("sfzh", sfzh);
        params.append("hhid", localStorage.getItem("bshtoken"));
        //获取人员身份证号并传递参数
        Toast.loading("加载中...",0,null,true);
        await Axios({
            method: 'post',
            url: url,
            data: params
        }).then((res) => {
            Toast.hide();
            if (res.status === 200) {
                var list = [];
                if (res.data) {
                    if (res.data.length > 0) {
                      for (var i = 0; i < res.data.length; i++) {
                        if(res.data[i].yxdqzt !=="无效"){
                        list.push(res.data[i])

                        }
                      }
                    }
                    this.setState({
                      cllist: list,
                      isLoaded: true
                    });
                    if (list.length === 0) {
                      Toast.info("暂未查到车辆信息")
                    }
                  } else {
                    Toast.info("暂未查到车辆信息")
                  }
                // this.setState({
                //     cllist: res.data,
                //     isLoaded: true
                // });

            }
        });
    }
    handleJump=(item)=>{
        if(item.yxdqzt=="待审核"){
            //跳转单据明细，并展示流程信息
            this.props.history.push({ pathname : "./clxxOrLc" , query : { clxx : item }});
        }else if(item.yxdqzt=="已审核"){
            //跳转到缴费页面，展示明细
            this.props.history.push({ pathname : "./clxxOrLc" , query : { clxx : item }});
        }else if(item.yxdqzt=="已缴费"){
            //跳转到明细表，展示流程和缴费明细
            this.props.history.push({ pathname : "./clxxOrLc" , query : { clxx : item }});
        }else if(item.yxdqzt=="已发放"){
            //展示上述所有信息
            this.props.history.push({ pathname : "./clxxOrLc" , query : { clxx : item }});
        }else if(item.yxdqzt=="登记"){

            this.showActionSheet(item)
        }else if(item.yxdqzt=="手机退回"){

            this.showActionSheet(item)
        }
    }
    showActionSheet = (item) => {
        console.log(item)
        console.log(item.cph)
        if(item.yxdqzt=="手机退回"){
            const BUTTONS = ['删除', '修改','查看详情', '取消'];
            ActionSheet.showActionSheetWithOptions({
                options: BUTTONS,
                cancelButtonIndex: BUTTONS.length - 1,
                destructiveButtonIndex: BUTTONS.length - 4,
                // title: 'title',
                message: '请选择您的操作',
                maskClosable: true,
                'data-seed': 'logId',
                wrapProps,
              },
              (buttonIndex) => {
                  console.log(buttonIndex);
                if(buttonIndex ===0){
                  Modal.alert('删除', '您是否删除此单据', [
                      { text: '取消', onPress: () => console.log('取消') },

                      {
                        text: '确定',
                        onPress: () =>
                          new Promise((resolve) => {
                            //跳转到登录
                            const url = BASE_URL+'bsqjlController/delete';
                              var params = new URLSearchParams();
                              params.append("sqid", item.sqid);
                              params.append("hhid", localStorage.getItem("bshtoken"));
                              //获取人员身份证号并传递参数
                              Toast.loading("加载中...",0,null,true);
                              Axios({
                                  method: 'post',
                                  url: url,
                                  data: params
                              }).then((res) => {
                                  Toast.hide();
                                  console.log(res)
                                  if(res.data.msg ==="删除成功！"){
                                      this.componentDidMount(res.data.msg)
                                  }else{
                                      Toast.info("删除失败！")
                                  }
                              });
                            setTimeout(resolve, 100);
                          }),
                      },
                    ])
                }
                else
                if(buttonIndex === 1){
                  this.props.history.push({ pathname : "./clcrsqUpdate" , query : { clxx : item }});
                }
                else
                if(buttonIndex === 2){
                  this.props.history.push({ pathname : "./clxxOrLc" , query : { clxx : item }});
                }
              });
        }else{
            const BUTTONS = ['查看详情', '取消'];
        ActionSheet.showActionSheetWithOptions({
          options: BUTTONS,
          cancelButtonIndex: BUTTONS.length - 1,
          destructiveButtonIndex: BUTTONS.length - 3,
          // title: 'title',
          message: '请选择您的操作',
          maskClosable: true,
          'data-seed': 'logId',
          wrapProps,
        },
        (buttonIndex) => {
            console.log(buttonIndex);

          if(buttonIndex === 0){
            this.props.history.push({ pathname : "./clxxOrLc" , query : { clxx : item }});
          }
        });
        }


      }
    setMapList = () => {
        if (!this.state.isLoaded) {
            return null;
        } else {
            return this.state.cllist.map((item, key) => (
                <Item multipleLine extra={item.crzlx} key={key} onClick={() => this.handleJump(item)}>
                    {item.cph} <Brief>{item.sqr}</Brief>
                    <Brief>{item.yxdqzt}</Brief>
                </Item>
            ))
        }
    }
    render() {
        return (
            <div style={{ marginTop: ".89rem" }}>
                {/* 导航栏#0F84D6 */}
                <NavBar
                    mode="dark"
                    icon={<Icon type="left" />}
                    rightContent={[
                        <div onClick={this.handleSq}>申请</div>
                      ]}
                    onLeftClick={() => this.props.history.push({
                      pathname: './main',
                      search: `?isNone='none'`
                  })}
                >车辆出入申请</NavBar>

                <div className="divmagin">
                <NoticeBar marqueeProps={{ loop: true, style: { padding: '0 7.5px' } }}>
                车辆出入申请提交后，预计三个工作日内审核完成。手机退回时，请查看退回原因，删除退回单据后重新提交申请
                </NoticeBar>
                    {this.setMapList()}
                </div>
            </div>
        )
    }
}
