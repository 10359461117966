import './ygkgbfjf.css'
import React, { Component } from 'react'
import { NavBar,Icon } from 'antd-mobile';
export default class Ygkgbfjf extends Component {
    render() {
        return (
            <div style={{ marginTop: ".89rem" }}>
            {/* 导航栏#0F84D6 */}
            <NavBar
              mode="dark"
              icon={<Icon type="left" />}
             onLeftClick={() => this.props.history.goBack()}
            >员工卡工本费缴费</NavBar>
            <div className="kf">正在开发中，敬请期待~</div>
            </div>
        )
    }
}
