import './wyyck.css'
import React, { Component } from 'react'
import { NavBar, Icon, SearchBar, List, Toast, Modal, Radio, Button, InputItem, Picker } from 'antd-mobile';
import { BASE_URL,WY_URL, YL_URL, PAGE_URL, Axios } from '../../../utils/url'
import Wxzf from '../../../assets/images/wxzf.png'
import Zfbzf from '../../../assets/images/zfbzf.png'
const Item = List.Item;
const Brief = Item.Brief;
const RadioItem = Radio.RadioItem;
export default class Wyyck extends Component {
    constructor(props) {
        super(props);
        var userInfo = "";
        const openid = this.props.location.search;
        if (openid.length > 0) {
            var open = openid.split("&");
            var openidArr = open[0].split("=");
            var openid1 = openidArr[1];
            var params = new URLSearchParams();
            localStorage.setItem("openID", openid1);
            params.append('openid', openid1);
            Axios({
                method: 'post',
                url: BASE_URL+"/bRyzcController/getUserInfo",
                data: params
            }).then((res) => {
                localStorage.setItem("userInfo", JSON.stringify(res.data.userInfo));
                localStorage.setItem("bshtoken", JSON.stringify(res.data.bshtoken));
                userInfo = JSON.parse(localStorage.getItem("userInfo"));
                this.setState({
                    yhxm: userInfo.ryxm,
                    yhid: userInfo.sfzh
                })
            });

        }
        this.state = {
            jfdjList: [],
            je: "",
            isLoaded: false,
            isLoad: false,
            mxList: [],
            modal: false,
            modal1: false,
            modalfwxz: false,
            modaljfxx: false,
            value: "",
            fjh: "",
            isMxCk: "",
            yhid: "",
            fwxxbdlist: [],
            jtzz: "",
            jtzhlist1: [{value: '8QQq265sZ1Gs1', label: '塔指幼儿园'}],
            jtzhlist2: [],
            jtzhlist3: []
        }
        this.handClose = this.handClose.bind(this);
    }
    componentDidMount = () => {
        if (this.isWeiXin()) {
            this.setState({
                value: "WXPay.jsPay"
            });
        } else {
            this.setState({
                value: "trade.precreate"
            });
        }
        this.getList();
        this.getjtzz("", "1");
    }
    async getjtzz(pid, type) {
        console.log(pid);
        console.log("获取家庭住址");
        let that = this;
        const url = WY_URL + 'yktsqxx/getJtzz';
        var params = new URLSearchParams();
        params.append("pid", pid);
        params.append("type", type);
        await Axios({
            method: 'post',
            url: url,
            data: params
        }).then((res) => {
            console.log(res.data.data);
            // for(var a= 0;a<res.data.data.length;a++){
            //     if(res.data.data[a].label ==='塔指幼儿园'){
            //         that.setState({
            //             jtzhlist1: res.data.data[a]
            //         });
            //     }
            // }
            // console.log(this.state.jtzhlist1);
            if (res.status === 200) {
                if (type == "1") {
                    // that.setState({
                    //     jtzhlist1: res.data.data
                    // });
                } else if (type == "2") {
                    that.setState({
                        jtzhlist2: res.data.data
                    });
                } else if (type == "3") {
                    that.setState({
                        jtzhlist3: res.data.data
                    });
                }

            }
        });
    }
    jtzhclick = (e) => {
        console.log(e);
        if (e == "2") {
            if (this.state.jtzhid1 != "") {
                this.getjtzz(this.state.jtzhid1, "2");
            } else {
                Toast.info('请先选择一级单位', 3, null, false);
            }
        }
        if (e == "3") {
            if (this.state.jtzhid2 != "") {
                this.getjtzz(this.state.jtzhid2, "3");
            } else {
                Toast.info('请先选择二级单位', 3, null, false);
            }
        }
    }
    jadzmc = () => {
        const list = this.state.jtzhlist3
        for (var item in list) {
            if (list[item].value == this.state.jtzhid3) { //item 表示Json串中的属性，如'name'
                var jValue = list[item];//key所对应的value
                console.log(jValue.label);
                this.setState({
                    jtzz: jValue.label,
                    modalfwxz: false,
                });
                this.setState({
                    fjh: jValue.label
                });
                var userInfo = JSON.parse(localStorage.getItem("userInfo"));
                this.getClglList(jValue.label, userInfo.sfzh);
            }
        }
    }
    onSubmit = (e) => {
        if (e.length < 12) {
            Toast.info("输入房号有误，请按照格式输入", 1, null, true);
        } else {
            this.setState({
                fjh: e
            });
            var userInfo = JSON.parse(localStorage.getItem("userInfo"));
            this.getClglList(e, userInfo.sfzh);
        }

    };
    async getList() {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        var params = new URLSearchParams();
        const url = WY_URL + 'grzy/profilebdlist'
        params.append("yhxm", userInfo.sfzh);
        await Axios({
            method: 'post',
            url: url,
            data: params
        }).then((res) => {
            console.log(res);
            this.setState({
                fwxxbdlist: res.data,
                isLoad: true
            });
        })
    }
    //获取列表
    async getClglList(e, sfzh) {
        const url = WY_URL + 'bsfxmjlController/YckPage';
        var params = new URLSearchParams();
        params.append("srfj", e);
        params.append("sfzh", sfzh);
        //获取人员身份证号并传递参数
        Toast.loading("加载中...", 0, null, true);
        await Axios({
            method: 'post',
            url: url,
            data: params
        }).then((res) => {
            Toast.hide();
            console.log(res);

            if (res.status === 200) {
                if(res.data.isMxCk){
                    if (res.data.list.length > 0) {
                        this.setState({
                            jfdjList: res.data.list,
                            // je: res.data.list[0].je,
                            isMxCk: res.data.isMxCk,
                            isLoaded: true,
                            modaljfxx: true
                        });

                    }
                }
                else {

                    alert("未查询到信息");
                }
            }
        });
    }
    setMapYckList = () => {
        if (!this.state.isLoad) {
            return null;
        } else {
            return this.state.jfdjList.map((item, key) => (
                <Item extra={item.je + "元"} align="middle"
                    thumb="https://zos.alipayobjects.com/rmsportal/dNuvNrtqUztHCwM.png"
                    multipleLine key={key} onClick={() => this.handlemx(item)}>
                    {/* {"*" + (item.ryxm).substring(1)}  */}
                    <Brief>家长姓名：{"*" + (item.a5).substring(1)}</Brief>
                    <Brief>孩子姓名：{"*" + (item.a6).substring(1)}</Brief>
                    <Brief>房屋编号：{item.a1}</Brief>
                </Item>
            ))
        }


    }

    // setJfxxList = () => {
    //     if (!this.state.isLoaded) {
    //         return null;
    //     } else {
    //         return this.state.jfdjList.map((item, key) => (
    //             <Item extra={item.je + "元"} align="middle"
    //                 thumb="https://zos.alipayobjects.com/rmsportal/dNuvNrtqUztHCwM.png"
    //                 multipleLine key={key}>
    //                 {/* {"*" + (item.ryxm).substring(1)}  */}
    //                 <Brief>家长姓名：{"*" + (item.a5).substring(1)}</Brief>
    //                 <Brief>孩子姓名：{"*" + (item.a6).substring(1)}</Brief>
    //                 <Brief>房屋编号：{item.a1}</Brief>
    //             </Item>
    //         ))
    //     }
    // }

    handlemx = (item) => {
        //console.log(item.fwbh);
        this.setState({
            modal1: true,
            je:item.je,
            orderid:item.a1,
            sfjlxh:item.sfjlxh
        });



    }
    // handlejfmx = (item) => {
    //     console.log(this.state.isMxCk);
    //     if (this.state.isMxCk) {
    //         const url = WY_URL + 'bgrfymxController/getMx';
    //         var params = new URLSearchParams();
    //         params.append("fybh", item.fybh);
    //         //获取人员身份证号并传递参数
    //         Toast.loading("加载中...", 0, null, true);
    //         Axios({
    //             method: 'post',
    //             url: url,
    //             data: params
    //         }).then((res) => {
    //             Toast.hide();
    //             if (res.status === 200) {
    //                 this.setState({
    //                     modal: true,
    //                     mxList: res.data
    //                 });
    //             }

    //         });
    //     }

    // }
    onClose = key => () => {
        this.setState({
            [key]: false,
        });
    }
    handClose = () => {
        this.setState({
            modaljfxx: false,
        });
    }

    //判断是否是微信浏览器的函数
    isWeiXin() {
        //window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
        var ua = window.navigator.userAgent.toLowerCase();
        //通过正则表达式匹配ua中是否含有MicroMessenger字符串
        if (ua.match(/MicroMessenger/i) == 'micromessenger') {
            return true;
        } else {
            return false;
        }
    }
    setmxList = () => {
        return this.state.mxList.map((item, key) => (
            <Item extra={item.je + "元"} align="middle"
                thumb="https://zos.alipayobjects.com/rmsportal/dNuvNrtqUztHCwM.png"
                multipleLine key={key} >
                {item.fylb}
                <Brief>{"单价：" + (item.flv == undefined ? "" : item.flv)}</Brief>
                <Brief>{"数量：" + (item.sl == undefined ? "" : item.sl)}</Brief>
            </Item>
        ))
    }
    onChange = (value, label) => {
        this.setState({
            value,
        });
    };
    setOrder = () => {
        // if (this.state.cje === '') {
        //     Toast.info("请输入充值金额", 1, null, false);
        // } else
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        if (this.state.value === '') {
            Toast.info("请选择支付类型", 1, null, false);
        } else {
            if (this.isWeiXin() && this.state.value === "trade.precreate") {
                let openId = localStorage.getItem("openID");
                //alert(openId);
                window.parent.frames.location.href = PAGE_URL + "?openId=" + openId + "&page=wyfjf";
                return;
            }
            // const url = YL_URL + 'xiadanController/order';
            var url = "";
            if (this.isWeiXin()) {
                url = YL_URL + 'xiadanWXController/order'
            } else {
                url = YL_URL + 'xiadanController/order';
            }
            var params = new URLSearchParams();

            params.append('totalAmount', Number(this.state.je));
            params.append('goodsId', this.state.sfjlxh);
            params.append('goodsName', "预存款");
            params.append('id', this.state.sfjlxh);
            params.append('price', this.state.je);
            params.append('payMethod', this.state.value);
            params.append('ywbm', "YCK");
            params.append('yhid', userInfo.sfzh);
            params.append('yhxm', userInfo.ryxm);
            Toast.loading("加载中...", 0, null, true);
            Axios({
                method: 'post',
                url: url,
                data: params
            }).then((res) => {
                Toast.hide();
                if (this.state.value == "WXPay.jsPay") {
                    if (res.status == '200') {
                        window.parent.frames.location.href = res.data.data;
                    } else {
                        alert("支付失败，请重新支付");
                    }
                } else {
                    if (res.status == '200') {
                        let jsonData = JSON.parse(res.data.data);
                        if (jsonData.errCode == 'SUCCESS' && jsonData.xdmsg == "下单成功!") {
                            let payJson = JSON.parse(jsonData.respStr);
                            this.setState((state, props) => {
                                return {
                                    zfdz: payJson.appPayRequest.qrCode,
                                    merOrderId: payJson.merOrderId
                                }
                            },
                                () => {
                                    //支付
                                    Modal.alert('确认支付？', '确认支付？', [
                                        { text: '取消', onPress: () => console.log('下次一定') },
                                        {
                                            text: '确定',
                                            onPress: () =>
                                                new Promise((resolve) => {
                                                    this.setState({
                                                        isPay: true
                                                    });
                                                    //跳转到登录
                                                    window.open(this.state.zfdz);
                                                    this.props.history.push({ pathname: '/wyyckjfqr', query: { merOrderId: this.state.merOrderId } });
                                                    setTimeout(resolve, 500);
                                                }),
                                        },
                                    ])
                                }
                            )

                        } else {
                            alert("支付失败，请重新支付");
                        }
                    }
                }
            });
        }

    }
    showModal = key => (e) => {
        if (this.state.sftzText == "否" && key == "modal1") {
            return;
        }
        this.setState({
            [key]: true,
        });
    }
    // handlebd = () => {
    //     this.props.history.push('/fwxxbdlist')
    // }
    render() {
        const { value } = this.state;
        const data = [
            //{ value: "WXPay.jsPay", label: '微信支付', img: Wxzf },
            //{ value: "trade.precreate", label: '支付宝支付', img: Zfbzf },
        ];
        if (this.isWeiXin()) {
            var obj = {};
            obj.value = "WXPay.jsPay";
            obj.label = "微信支付";
            obj.img = Wxzf;
            data.push(obj);
            var obj1 = {};
            obj1.value = "trade.precreate";
            obj1.label = "支付宝支付";
            obj1.img = Zfbzf;
            data.push(obj1);
        } else {
            var obj = {};
            obj.value = "trade.precreate";
            obj.label = "支付宝支付";
            obj.img = Zfbzf;
            data.push(obj);
        }
        return (
            <div style={{ marginTop: ".89rem" }}>
                {/* 导航栏#0F84D6 */}
                <NavBar
                    mode="dark"
                    icon={<Icon type="left" />}
                    onLeftClick={() => this.props.history.push({
                        pathname: './main', search: `?isNone='none'`})}
                    // rightContent={[<div key="1" onClick={() => this.handlebd()}>绑定</div>]}
                >幼儿费</NavBar>
                <div className="kf">

                    {/* <SearchBar placeholder="请输入房号，格式为：08-11-01-0101" maxLength={13}
                        onSubmit={this.onSubmit} showCancelButton={true} cancelText='选择'  onCancel={this.showModal('modalfwxz')}/> */}
                         <SearchBar placeholder="请输入房号，格式为：08-11-01-0101" maxLength={13}
                        onSubmit={this.onSubmit}  cancelText={"查询"} onCancel={this.onSubmit}/>
                    {/* <InputItem placeholder="请选择房号" value={this.state.jtzz} onClick={this.showModal('modalfwxz')} onChange={(value) => this.setState({ jtzz: value })}>
                        <div ><span className="error">*</span>房号选择</div>
                    </InputItem> */}
                    <div className="gs" >
                        *请输入房号,格式为：08-11-01-0101
                    </div>
                    <div className="gs" >
                        *支付金额较大时，微信需要验证身份，请按要求操作
                    </div>

                    <div style={{marginTop:"-25px"}}>
                    <List renderHeader={() => ''} >
                        {this.setMapYckList()}
                    </List>
                </div>
                </div>
                {/* <Modal
                    style={{ height: "60%", overflow: "auto" }}
                    // title="缴费信息"
                    popup
                    // closable={true}
                    visible={this.state.modaljfxx}
                    onClose={this.onClose('modaljfxx')}
                    animationType="slide-up"
                    afterClose={() => { console.log('afterClose'); }}
                >
                    <div style={{ cursor: "pointer" }}>
                        <div type="ghost" inline size="small"
                            style={{ marginRight: '4px', position: "absolute", left: "10px", top: "5px", color: "#108EE9", cursor: "pointer", fontSize: "18px", fontWeight: 500 }}
                            onClick={this.handClose}>关闭</div>
                        <div type="ghost" inline size="small"
                            style={{ marginRight: '4px', position: "absolute", right: "10px", top: "5px", color: "#108EE9", cursor: "pointer", fontSize: "18px", fontWeight: 500 }}
                            onClick={this.handleJump}>支付</div>
                    </div>

                    {/* <List renderHeader={() => <div>预存款单据</div>} className="popup-list" style={{ marginTop: "30px" }}>
                        {this.setJfxxList()}
                    </List>
                </Modal> */}
                <Modal
                    style={{ height: "60%", overflow: "auto" }}
                    title="缴费明细"
                    popup
                    closable={true}
                    visible={this.state.modal}
                    onClose={this.onClose('modal')}
                    animationType="slide-up"
                    afterClose={() => { console.log('afterClose'); }}
                >
                    <List renderHeader={() => ''} >
                        {this.setmxList()}
                    </List>
                </Modal>
                <Modal
                    popup
                    visible={this.state.modal1}
                    onClose={this.onClose('modal1')}
                    animationType="slide-up"
                    afterClose={() => { console.log('afterClose'); }}
                >
                    <List renderHeader={() => '缴费金额'}>
                        <InputItem
                            type='number'
                            placeholder="请输入充值金额"
                            value={this.state.je}
                            editable={false}
                            extra="元"
                        >充值金额</InputItem>
                    </List>
                    <List renderHeader={() => '选择支付方式'}>
                        {data.map(i => (
                            <RadioItem key={i.value} checked={value === i.value} onChange={() => this.onChange(i.value, i.label)}>
                                <img src={i.img} />  {i.label}
                            </RadioItem>
                        ))}
                        <Button type="primary" onClick={this.setOrder}>确认</Button>
                    </List>
                </Modal>
                <Modal
                    popup
                    visible={this.state.modalfwxz}
                    onClose={this.onClose('modalfwxz')}
                    animationType="slide-up"
                    afterClose={() => { console.log('afterClose'); }}
                >
                    <List renderHeader={() => '详情信息'} >
                        <Picker
                            data={this.state.jtzhlist1}
                            cols={1}
                            onOk={(value) => this.setState({ jtzhid1: value })}
                            value={this.state.jtzhid1}
                        >
                            <List.Item arrow="horizontal">小区</List.Item>
                        </Picker>

                        <Picker
                            data={this.state.jtzhlist2}
                            cols={1}
                            onChange={(value) => this.setState({ jtzhid2: value })}
                            value={this.state.jtzhid2}
                        >
                            <List.Item arrow="horizontal" onClick={() => this.jtzhclick("2")}>楼宇</List.Item>
                        </Picker>

                        <Picker
                            data={this.state.jtzhlist3}
                            cols={1}
                            onChange={(value) => this.setState({ jtzhid3: value })}
                            value={this.state.jtzhid3}
                        >
                            <List.Item arrow="horizontal" onClick={() => this.jtzhclick("3")}>房号</List.Item>
                        </Picker>
                    </List>
                    <List.Item>
                        <Button type="primary" onClick={() => this.jadzmc()}>确认</Button>
                    </List.Item>
                </Modal>
            </div>
        )
    }
}
