import './cljfdjf.css'
import React, { Component } from 'react'
import { BASE_URL,YL_URL, Axios } from  '../../../utils/url'
import Wxzf from '../../../assets/images/wxzf.png'
import Zfbzf from '../../../assets/images/zfbzf.png'
import { NavBar, Icon, List, InputItem,Modal, Toast, Radio,Button } from 'antd-mobile';
const RadioItem = Radio.RadioItem;
export default class index extends Component {
    constructor(props) {
        super(props);
        const cph = this.props.location.query.item.cph;
        this.state = ({
            cph: cph,
            id:'',
            zfdz:"",
            merOrderId:"",
            isPay:false,
            modal: false,
            value: "",
        });
    }
    componentDidMount = () => {
        this.getdjmxList();

    }
    onClose = key => () => {
        this.setState({
            [key]: false,
        });
    }
    //获取当前车辆缴费信息
    async getdjmxList() {
        const url = BASE_URL+'bjfjlController/getList';
        var params = new URLSearchParams();
        //获取车辆缴费信息，传递参数车牌号

        params.append("cph",this.state.cph);
        params.append("dqzt","待缴费");
        await Axios({
            method: 'post',
            url: url,
            data: params
        }).then((res) => {
            if (res.status === 200) {
                this.setState({
                    id: res.data[0],
                    isLoaded: true
                });

            }
        });
    }
    // 获取选择车库信息
    onChange = (value, label) => {
        this.setState({
            value,
        });
    };
    handleOrder = (item) => {
        if (this.state.id.je === 0) {
            // String jfid,String zfzt,String zfsj,String jffs,String cph,String sl,
            // String je,String dj,String jldw,String jfxmmc,String jfxmid
            const url = BASE_URL + 'bjfjlController/save';
            var params = new URLSearchParams();
            //获取车辆缴费信息，传递参数车牌号
            params.append("jfid", this.state.id.jfid);
            params.append("zfzt", "已缴费");
            params.append("jffs", "现金支付");
            params.append("cph", this.state.cph);
            params.append("sl", this.state.clxx.sl);
            params.append("je", this.state.clxx.je);
            params.append("dj", this.state.clxx.dj);
            params.append("jldw", this.state.clxx.jldw);
            params.append("jfxmmc", this.state.clxx.jfxmmx);
            params.append("jfxmid", this.state.clxx.jfxmid);
            params.append("jfje", this.state.clxx.jfje);

            Toast.loading("加载中...", 0, null, true);
            Axios({
                method: 'post',
                url: url,
                data: params
            }).then((res) => {
                Toast.hide();
                if (res.status == 200) {
                    Toast.info("缴费成功", 1, null, false);
                    this.props.history.go(-1)
                }
            });
        } else {
            this.setState({
                modal: true
            });
        }
    }
    //判断是否是微信浏览器的函数
    isWeiXin() {
        //window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
        var ua = window.navigator.userAgent.toLowerCase();
        //通过正则表达式匹配ua中是否含有MicroMessenger字符串
        if (ua.match(/MicroMessenger/i) == 'micromessenger') {
            return true;
        } else {
            return false;
        }
    }
    setOrder = () => {
        if (this.state.czje === '') {
            Toast.info("请输入充值金额", 1, null, false);
        } else if (this.state.value === '') {
            Toast.info("请选择支付类型", 1, null, false);
        } else {
            //const url = YL_URL + 'xiadanController/order';
            var  url ="";

            if(this.isWeiXin()){
                url =YL_URL + 'xiadanWXController/order'
            }else{
                url = YL_URL + 'xiadanController/order';
            }
            var params = new URLSearchParams();
            params.append('totalAmount', this.state.id.je);
            params.append('goodsId', this.state.id.jfid);
            params.append('goodsName', this.state.id.bz);
            params.append('id', this.state.id.cph);
            params.append('price', this.state.id.je);
            params.append('payMethod', this.state.value);
            params.append('ywbm', "CLJF");
            params.append("openid", localStorage.getItem("openId"));
            Toast.loading("加载中...", 0, null, true);
            Axios({
                method: 'post',
                url: url,
                data: params
            }).then((res) => {
                Toast.hide();
                if(this.state.value=="WXPay.jsPay"){
                    if (res.status == '200') {
                    window.parent.frames.location.href = res.data.data;
                } else {
                    alert("支付失败，请重新支付");
                }
                }else{
                if (res.status == '200') {
                    let jsonData = JSON.parse(res.data.data);
                    if (jsonData.errCode == 'SUCCESS' && jsonData.xdmsg == "下单成功!") {
                        let payJson = JSON.parse(jsonData.respStr);
                        this.setState((state, props) => {
                            return {
                                zfdz: payJson.appPayRequest.qrCode,
                                merOrderId: payJson.merOrderId
                            }
                        },
                            () => {
                                //支付
                                Modal.alert('确认支付？', '确认支付？', [
                                    { text: '取消', onPress: () => console.log('下次一定') },
                                    {
                                        text: '确定',
                                        onPress: () =>
                                            new Promise((resolve) => {
                                                this.setState({
                                                    isPay: true
                                                });
                                                //跳转到登录
                                                window.open(this.state.zfdz);
                                                this.props.history.push({ pathname: '/jfqr', query: { merOrderId: this.state.merOrderId } });
                                                setTimeout(resolve, 500);
                                            }),
                                    },
                                ])
                            }
                        )

                    } else {
                        alert("支付失败，请重新支付");
                    }
                }
                }


            });
        }

    }


    render() {
        const { value } = this.state;
        var  data = [
            //{ value: "WXPay.jsPay", label: '微信支付', img: Wxzf },
            //{ value: "trade.precreate", label: '支付宝支付', img: Zfbzf },
        ];
        if(this.isWeiXin()){
            var obj= {};
            obj.value = "WXPay.jsPay";
            obj.label = "微信支付";
            obj.img = Wxzf;
            data.push(obj);
        }else{
            var obj= {};
            obj.value = "trade.precreate";
            obj.label = "支付宝支付";
            obj.img = Zfbzf;
            data.push(obj);
        }
        return (
            <div style={{ marginTop: ".89rem" }}>

                {/* 导航栏#0F84D6 */}
                <NavBar
                    mode="dark"
                    icon={<Icon type="left" />}
                    onLeftClick={() => this.props.history.push({
                        pathname: './main',
                        search: `?isNone='none'`
                    })}
                >{this.state.id.cph}</NavBar>
                <div className="divmagin">
                    <List renderHeader={() => '缴费信息'} >
                        <InputItem
                            type="money"
                            value={this.state.id.cph}
                            editable={false}
                        ><div>车牌号</div></InputItem>
                        <InputItem
                            type="money"
                            value={this.state.id.bz}
                            editable={false}
                        ><div>收费项目</div></InputItem>
                        <InputItem
                            type="money"
                            value={this.state.id.je + "元"}
                            editable={false}
                        ><div>应收总金额</div></InputItem>
                        <InputItem
                            type="money"
                            value={this.state.id.dqzt}
                            editable={false}
                        ><div>当前状态</div></InputItem>
                    </List>
                </div>
                <button className="submit" type="submit" onClick={this.handleOrder}>
                    支付
                </button>
                {/* 选择排号车库 */}
                <Modal
                    popup
                    visible={this.state.modal}
                    onClose={this.onClose('modal')}
                    animationType="slide-up"
                    afterClose={() => { console.log('afterClose'); }}
                >
                    <List renderHeader={() => '充值金额'}>
                        <InputItem
                            type='number'
                            placeholder="请输入充值金额"
                            value={this.state.id.je}
                            disabled={true}
                            extra="元"
                        >充值金额</InputItem>
                    </List>
                    <List renderHeader={() => '选择支付方式'}>
                        {data.map(i => (
                            <RadioItem key={i.value} checked={value === i.value} onChange={() => this.onChange(i.value, i.label)}>
                                <img src={i.img} />  {i.label}
                            </RadioItem>
                        ))}
                        <Button type="primary" onClick={this.setOrder}>确认</Button>
                    </List>
                </Modal>
            </div>
        )
    }
}
